import { combineReducers } from 'redux';

import AIGeneratedAnalysis from '../modules/AIGeneratedAnalysis/redux';
import { AiGeneratedAnalysisState } from '../modules/AIGeneratedAnalysis/types';
import AnalyzeInternalData from '../modules/AnalyzeInternalData/redux';
import { AnalyzeInternalDataState } from '../modules/AnalyzeInternalData/types';
import { SIGNOUT_REQUEST } from '../modules/Authentication/actions/user';
import Authentication, { AuthenticationState } from '../modules/Authentication/reducers/authentication';
import Ecosystem, { EcosystemState } from '../modules/Ecosystem/reducers/ecosystem';
import ProductMarketFit from '../modules/Ecosystem/reducers/productMarketFit';
import { ProductMarketFitState } from '../modules/Ecosystem/types/productMarketFit';
import GlobalSettings from '../modules/GlobalSettings/reducers/globalSettings';
import { GlobalSettingsState } from '../modules/GlobalSettings/types/ecosystemSettingsCompanyTags';
import WebPubSub from '../modules/Home/reducers/webpubsub';
import { PubSubStates } from '../modules/Home/types/webpubsub';
import Model from '../modules/ModelScreen/reducers/model';
import { ModelState } from '../modules/ModelScreen/types/model';
import PlaygroundAnalysis from '../modules/Playground/redux';
import { SectionsState } from '../modules/Playground/types';
import Projects from '../modules/Portfolio/reducers/project';
import { ProjectsState } from '../modules/Portfolio/types/project';
import Scenario from '../modules/Scenarios/reducers/scenario';
import { ScenarioState } from '../modules/Scenarios/types/scenario';
import ScenariosAnalysis from '../modules/ScenariosAnalysis/redux';
import { ScenariosAnalysisState } from '../modules/ScenariosAnalysis/types';
import Search from '../modules/Search/reducers/search';
import SearchSignals from '../modules/Search/reducers/searchSignals';
import { SearchState } from '../modules/Search/types/searchResults';
import { SearchSignalsState } from '../modules/Search/types/searchSignals';

export interface ReduxState {
  Authentication: AuthenticationState;
  Projects: ProjectsState;
  Ecosystem: EcosystemState;
  ProductMarketFit: ProductMarketFitState;
  Model: ModelState;
  Scenario: ScenarioState;
  SearchSignals: SearchSignalsState;
  Search: SearchState;
  GlobalSettings: GlobalSettingsState;
  ScenariosAnalysis: ScenariosAnalysisState;
  WebPubSub: PubSubStates;
  AnalyzeInternalData: AnalyzeInternalDataState;
  AIGeneratedAnalysis: AiGeneratedAnalysisState;
  PlaygroundAnalysis: SectionsState;
}

const appReducer = combineReducers<ReduxState>({
  Authentication,
  Projects,
  Ecosystem,
  ProductMarketFit,
  Model,
  Scenario,
  SearchSignals,
  Search,
  GlobalSettings,
  ScenariosAnalysis,
  WebPubSub,
  AnalyzeInternalData,
  AIGeneratedAnalysis,
  PlaygroundAnalysis,
});

const RootReducer = (state?: ReduxState, action?: any) => {
  if (action.type === SIGNOUT_REQUEST) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default RootReducer;
