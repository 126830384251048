import { GenerateInsightsReqPayload } from './insights';

export interface AnalyzeInternalDataAction<T> {
  type: ACTIONS;
  data?: Partial<T>;
}

export interface AnalyzeInternalDataState extends GenerateInsightsReqPayload {
  loading: boolean;
  insights: string[];
}

export enum ACTIONS {
  CLEAR_STATE = 'CLEAR_STATE',
  INSIGHTS_PENDING = 'INSIGHTS_PENDING',
  INSIGHTS_COMPLETED = 'INSIGHTS_COMPLETED',
  INSIGHTS_SUCCESS = 'INSIGHTS_SUCCESS',
  SET_FORM_DATA = 'SET_FORM_DATA',
  SET_INSTRUCTION = 'SET_INSTRUCTION',
}
