import { ACTIONS, ScenariosAnalysisAction, ScenariosState } from '../../types';

const initialState: ScenariosState = {
  loading: false,
  selected: '',
  scenarios: [],
};

export const reducer = (state = initialState, action: ScenariosAnalysisAction<ScenariosState>): ScenariosState => {
  switch (action.type) {
    case ACTIONS.CLEAR_SCENARIOS:
      return { ...state, scenarios: [] };
    case ACTIONS.CLEAR_SELECTED_SCENARIO:
      return { ...state, selected: '' };
    case ACTIONS.SET_SELECTED_SCENARIO:
      return { ...state, selected: action.data?.selected ?? '' };
    case ACTIONS.SCENARIOS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SCENARIOS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SCENARIOS_SUCCESS:
      return {
        ...state,
        scenarios: action.data?.scenarios ?? [],
      };
    default:
      return state;
  }
};
