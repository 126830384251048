import { Anchor } from './anchor';
import { Note } from './note';
// Action constants
export const SET_ASPECTS = 'SET_ASPECTS';
export const SET_ASPECT_DATA = 'SET_NOTES_AND_ANCHOR_OF_ASPECT';
export const UPDATE_ASPECT_DATA = 'UPDATE_NOTES_AND_ANCHOR_OF_ASPECT';
export const SET_ASPECT_NOTES = 'SET_ASPECT_NOTES';
export const CLEAR_ASPECT_DATA = 'CLEAR_ASPECT_DATA';
export const SET_ALL_ORIGINAL_ASPECT_ITEMS = 'SET_ALL_ORIGINAL_ASPECT_ITEMS';
export const SET_SELECTED_ASPECT_PAIR = 'SET_SELECTED_ASPECT_PAIR';

export const EMPTY_ASPECT_PAIR = {
  pair: '',
  left: '',
  right: '',
  next_pair_left: '',
  next_pair_right: '',
};

// Action constants ends here
export interface Aspect {
  id: string;
  type: string;
}

export interface AspectResponse {
  isSuccess: boolean;
  data?: AspectStructure;
  status: number;
  message: string;
}

export interface AspectBody {
  notes: Note[];
  orphan_anchors_recommendations: Anchor[];
  score: number;
}

export interface AspectDataResponse {
  isSuccess: boolean;
  data?: AspectBody;
  status: number;
}

export interface AspectPair {
  pair: string;
  left: string;
  right: string;
  next_pair_left: string;
  next_pair_right: string;
}

export interface AspectStructure {
  aspects: Aspect[];
  structure: AspectPair[];
  default: string;
}

export interface AllAspectDetails extends AspectBody {
  id: string;
  projectId?: string;
  companyId?: string;
}

export interface UpdateAspectDetailsBody {
  data: Note | Anchor[];
  allAspectDetails: AllAspectDetails[];
  aspectId: string;
  noteId: string;
}

export interface GetAspectNotesBody {
  project_id: string;
  aspect_id: string;
}

export interface GetAspectResponse {
  isSuccess: boolean;
  data?: { notes: AspectNote[] };
  status: number;
}

export interface AspectNote {
  note_id: string;
  name: string;
}
