import loadable from '@loadable/component';
import makeStyles from '@mui/styles/makeStyles';
import axios, { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import React, { Dispatch, createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router';

import {
  AppState,
  HISTORY,
  LOGIN,
  LOGOUT,
  RETRIVE_LOGIN_DETAILS,
  ReduxAction,
  SET_REDIRECT_URL,
  TOGGLE_DRAWER,
} from './App.type';
import { useBaseStyles } from './common/styles/useBaseStyles';
import { collapsedWidth, expandedWidth } from './constants/drawer';
import ROUTE_KEYS, { UN_AUTH_ROUTES } from './constants/routeKeys';
import { clearAiGeneratedAnswers } from './modules/AIGeneratedAnalysis/redux';
import AxiosInstance from './modules/Api/axios';
import {
  getUserPlanById,
  retriveLoggedInUser,
  retriveLoggedInUserId,
  setUserPlans,
  signout,
} from './modules/Authentication/actions/user';
import { fetchProjectById, setSelectedProject } from './modules/Portfolio/actions/project';
import { ReduxState } from './reducers';
import { ParamsState } from './routes/types/index';
import { clearSwCache, getTheme } from './utils';

import './App.css';
import 'driver.js/dist/driver.css';

const Routes = loadable(() => import('./routes/index'));
const MiniDrawer = loadable(() => import('./modules/Navigation/navbar'));
const Grid = loadable(() => import('@mui/material/Grid'));
const Loading = loadable(() => import('./commonComponents/loading'));
const NewVersionReleaseModal = loadable(() => import('./modules/Home/versionUpdateModal'));
const WebPubSub = loadable(() => import('./modules/Home/webpubsub'));
const Snackbar = loadable(() => import('./commonComponents/snackbar'));

const initialState: AppState = {
  isAuthenticated: false,
  user: {},
  token: '',
  history: {},
  redirectUrl: '',
  leftDrawerExpanded: false,
  drawerWidth: collapsedWidth,
};

export const AuthContext = createContext<{
  state: AppState;
  dispatch: Dispatch<ReduxAction<unknown>>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (actionState: AppState, action: ReduxAction<any>): AppState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...actionState,
        isAuthenticated: true,
        user: action.data.user,
        token: action.data.token,
      };
    case LOGOUT:
      return {
        ...actionState,
        isAuthenticated: false,
        user: {},
        token: '',
      };
    case RETRIVE_LOGIN_DETAILS:
      return {
        ...actionState,
        isAuthenticated: action.data.isAuthenticated,
        user: action.data.user,
        token: action.data.token,
      };
    case HISTORY:
      return {
        ...actionState,
        history: action.data,
      };
    case SET_REDIRECT_URL:
      return {
        ...actionState,
        redirectUrl: action.data,
      };
    case TOGGLE_DRAWER:
      return {
        ...actionState,
        leftDrawerExpanded: action.data,
        drawerWidth: action.data ? expandedWidth : collapsedWidth,
      };
    default:
      return actionState;
  }
};

const App = () => {
  const {
    Authentication: { userDetails: sessionUser, userId },
    Projects: {
      selectedProject: { _id: projectId },
    },
  } = useSelector((state: ReduxState) => state);

  const theme = getTheme(sessionUser);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [appLoading, setAppLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ visible: false, message: '' });

  const history = useHistory();
  const location = useLocation();

  const reduxDispatch = useDispatch();

  const commonClasses = useBaseStyles({ theme });
  const classes = useStyles({
    drawerWidth: history.location.pathname === ROUTE_KEYS.PROJECTS ? 0 : state.drawerWidth,
    expanded: state.leftDrawerExpanded,
  });

  useEffect(() => {
    setAxiosDefaults();
    clearSwCache();
    authInterceptor();
    historyListener();
    getSessionUser();
  }, []);

  useEffect(() => {
    dispatch({ type: HISTORY, data: history });
  }, [history]);

  useEffect(() => {
    reduxDispatch(clearAiGeneratedAnswers());
  }, [projectId]);

  useEffect(() => {
    dispatch({
      type: RETRIVE_LOGIN_DETAILS,
      data: {
        isAuthenticated: !!(sessionUser && sessionUser._id),
        user: sessionUser,
        token: '',
      },
    });

    if (sessionUser && sessionUser._id) {
      getProjectsByUserId();
    }
  }, [sessionUser]);

  useEffect(() => {
    if (userId) {
      retriveLoggedInUser(userId)(reduxDispatch);
      fetchUserPlans(userId);
    }
  }, [userId]);

  const setAxiosDefaults = () => {
    const getServiceBaseUrl = () => {
      const currentEnv = window.location.hostname.split('.')[0];

      if (currentEnv === 'platform') return process.env.RAZZLE_API_BASE_URL_PLATFORM;
      if (currentEnv === 'test') return process.env.RAZZLE_API_BASE_URL_TEST;

      return 'http://localhost:3000/';
    };

    const SERVER_URL = getServiceBaseUrl();

    AxiosInstance.defaults.baseURL = SERVER_URL;
    axios.defaults.baseURL = SERVER_URL;
  };

  const historyListener = () => {
    history.listen(() => {
      dispatch({ type: HISTORY, data: history });
    });
  };

  const authInterceptor = () => {
    AxiosInstance.interceptors.response.use(
      (response: AxiosResponse<unknown>) => {
        return response;
      },
      async (error: AxiosError<{ sessionExpired?: boolean; message?: string }>) => {
        const resp = error.response;
        const sessionExpired = resp?.data.sessionExpired ?? resp?.status === 401;
        const isPrivateRoute = checkRouteType(history.location.pathname);

        if (sessionExpired && isPrivateRoute) {
          toggleSnackbar(resp?.data.message ?? '', true);
          await signout()(reduxDispatch);
          history.push(ROUTE_KEYS.SIGN_IN);
        }

        return Promise.reject(error);
      }
    );
  };

  const checkRouteType = (path: string) => {
    const isResetPassRoute = matchPath(path, {
      path: ROUTE_KEYS.RESET_PASS,
      exact: true,
      strict: false,
    });
    const isInviteCollaboratorRoute = matchPath(path, {
      path: ROUTE_KEYS.ACCEPT_INVITE,
      exact: true,
      strict: false,
    });
    return !(UN_AUTH_ROUTES.indexOf(path) !== -1 || isResetPassRoute || isInviteCollaboratorRoute || path === '/');
  };

  const getSessionUser = async () => {
    setAppLoading(true);
    const { data: userId } = await retriveLoggedInUserId()(reduxDispatch);

    if (!userId) {
      logoutUser();
    }
  };

  const logoutUser = () => {
    dispatch({ type: LOGOUT, data: {} });
    navigateIntoApp();
  };

  const fetchUserPlans = async (userId: string) => {
    try {
      const result = await getUserPlanById(userId)(reduxDispatch);
      setUserPlans(result);
    } catch (err) {
      console.error('err in fetchUserPlans', err);
    }
  };

  const getCurrentProjectId = () => {
    const currentRouteUrl: string = state.redirectUrl ? state.redirectUrl : history.location.pathname;
    const urlProjectId = getUrlProjectId(currentRouteUrl);
    let projectId = '';
    const routeState = history.location.state as ParamsState;
    if (routeState && routeState.projectId) {
      projectId = routeState.projectId;
    } else if (urlProjectId) {
      projectId = urlProjectId;
    } else {
      projectId = sessionUser && sessionUser.defaultProject ? sessionUser.defaultProject : '';
    }
    return { projectId, currentRouteUrl };
  };

  const getProjectsByUserId = async () => {
    const { projectId, currentRouteUrl } = getCurrentProjectId();

    if (!projectId || !currentRouteUrl) {
      return navigateIntoApp();
    }

    const { isSuccess, data, message, status } = (await fetchProjectById(projectId)) as any;

    if (isSuccess && data && data._id) {
      reduxDispatch(setSelectedProject(data));
    } else {
      if (status === 401) {
        history.push(ROUTE_KEYS.PROJECTS);
      }

      toggleSnackbar(message, true);
    }

    navigateIntoApp();
  };

  const getUrlProjectId = (path: string) => {
    let urlProjectId = '';
    const isPublicRoute = UN_AUTH_ROUTES.indexOf(path) !== -1;
    if (path && (path !== ROUTE_KEYS.PROJECTS || !isPublicRoute)) {
      const projectIdSplitted = path.split('projects/');
      if (projectIdSplitted.length > 1) {
        urlProjectId = path.split('projects/')[1].split('/')[0];
      }
    }
    return urlProjectId;
  };

  const navigateIntoApp = () => {
    setAppLoading(false);

    if (sessionUser && sessionUser._id) {
      if (history.location.pathname === '/') {
        history.replace(ROUTE_KEYS.PROJECTS);
      }
    } else {
      const isPrivateRoute = checkRouteType(history.location.pathname);
      if (isPrivateRoute) {
        history.replace(ROUTE_KEYS.SIGN_IN);
      }
    }
  };

  const toggleSnackbar = (msg: string, status?: boolean) => {
    setSnackbar({ visible: !!status, message: msg });
  };

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <Snackbar message={snackbar.message} visible={snackbar.visible} toggleSnackbar={toggleSnackbar} duration={5000} />
      <main>
        <MiniDrawer />
        {appLoading ? (
          <Grid container className={commonClasses.loadingContainer}>
            <Loading loading={appLoading} />
          </Grid>
        ) : (
          <Grid
            container
            className={clsx(classes.content, {
              [classes.playgroundContainer]: location.pathname.includes(
                `${ROUTE_KEYS.RESEARCH}${ROUTE_KEYS.RESEARCH_PLAYGROUND}`
              ),
            })}
          >
            <NewVersionReleaseModal />
            <Routes />
            <WebPubSub />
          </Grid>
        )}
      </main>
    </AuthContext.Provider>
  );
};

export default App;

const useStyles = makeStyles(() => ({
  content: {
    height: '93vh',
    flex: 1,
    width: '100vw',
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: 'transparent',
  },
  loadingContainer: {
    height: '90vh',
    width: '95vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playgroundContainer: {
    maxHeight: '93vh',
    overflow: 'hidden',
  },
}));
