import { Actions } from '../../Ecosystem/types/ecosystem';
import { SET_ANCHOR_COUNT, SET_ANCHOR_FILTERS, SET_ANCHOR_RESULTS } from '../types/searchAnchors';
import {
  SET_NOTES_COUNT,
  SET_NOTES_FILTER,
  SET_NOTES_RESULTS,
  SET_NOTES_SELECTED_FILTER,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
} from '../types/searchNotes';
import { SET_INITIAL_STATE, SET_PROJECT_ID, SET_SEARCH_PHARSE, SearchState } from '../types/searchResults';
import { SET_SCENARIO_COUNT, SET_SCENARIO_FILTERS, SET_SCENARIO_RESULTS } from '../types/searchScenario';
import { SET_SIGNAL_COUNT, SET_SIGNAL_FILTERS, SET_SIGNAL_RESULTS } from '../types/searchSignals';
import { noteDefaultPage, noteDefaultPageSize } from '../utils/constant';

const initialState: SearchState = {
  projectId: '',
  searchPhrase: '',
  noteList: {
    pageNumber: noteDefaultPage,
    rowsPerPage: noteDefaultPageSize,
    notes: [],
    filters: [],
    selectedFilters: [],
    totalNoteCount: 0,
  },
  signal: {
    filters: [],
    count: 0,
    results: [],
  },
  anchor: {
    filters: [],
    count: 0,
    results: [],
  },
  scenario: {
    filters: [],
    count: 0,
    results: [],
  },
};
const Search = (state = initialState, actions: Actions) => {
  switch (actions.type) {
    case SET_INITIAL_STATE:
      return {
        ...initialState,
      };
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: actions.data,
      };
    case SET_SEARCH_PHARSE:
      return {
        ...state,
        searchPhrase: actions.data,
      };
    // Note Actions start here
    case SET_NOTES_FILTER:
      return {
        ...state,
        noteList: { ...state.noteList, filters: actions.data },
      };
    case SET_NOTES_COUNT:
      return {
        ...state,
        noteList: { ...state.noteList, totalNoteCount: actions.data },
      };
    case SET_NOTES_RESULTS:
      return {
        ...state,
        noteList: { ...state.noteList, notes: actions.data },
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        noteList: { ...state.noteList, pageNumber: actions.data },
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        noteList: { ...state.noteList, rowsPerPage: actions.data },
      };
    case SET_NOTES_SELECTED_FILTER:
      return {
        ...state,
        noteList: { ...state.noteList, selectedFilters: actions.data },
      };
    // Note Actions end here

    // Signals action start here
    case SET_SIGNAL_COUNT:
      return {
        ...state,
        signal: { ...state.signal, count: actions.data },
      };
    case SET_SIGNAL_FILTERS:
      return {
        ...state,
        signal: { ...state.signal, filters: actions.data },
      };
    case SET_SIGNAL_RESULTS:
      return {
        ...state,
        signal: { ...state.signal, results: actions.data },
      };
    // Signals action end here

    // Anchor action start here
    case SET_ANCHOR_COUNT:
      return {
        ...state,
        anchor: { ...state.anchor, count: actions.data },
      };
    case SET_ANCHOR_FILTERS:
      return {
        ...state,
        anchor: { ...state.anchor, filters: actions.data },
      };
    case SET_ANCHOR_RESULTS:
      return {
        ...state,
        anchor: { ...state.anchor, results: actions.data },
      };
    // Anchor action end here

    // Scenario action start here
    case SET_SCENARIO_COUNT:
      return {
        ...state,
        scenario: { ...state.scenario, count: actions.data },
      };
    case SET_SCENARIO_FILTERS:
      return {
        ...state,
        scenario: { ...state.scenario, filters: actions.data },
      };
    case SET_SCENARIO_RESULTS:
      return {
        ...state,
        scenario: { ...state.scenario, results: actions.data },
      };
    // Scenario action end here

    default:
      return state;
  }
};

export default Search;
