import {
  ACTIONS,
  AIGeneratedAnswersAction,
  AiGeneratedAnalysisSignals,
  AnchorForTopic,
  CompanyForTopic,
  GeneratedAnswer,
} from '../../types';

export const setFrame = (value: string[]): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_FRAME,
    data: { frame: value },
  };
};

export const setAnchorsForTopic = (value: AnchorForTopic[]): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_ANCHORS_FOR_TOPIC,
    data: { anchors: value },
  };
};

export const clearAnchorsForTopic = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_ANCHORS_FOR_TOPIC,
  };
};

export const setCompaniesForTopic = (
  value: CompanyForTopic[]
): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_COMPANIES_FOR_TOPIC,
    data: { companies: value },
  };
};

export const clearCompaniesForTopic = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_COMPANIES_FOR_TOPIC,
  };
};

export const setQuestionForTopic = (value: string[]): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_QUESTIONS_FOR_TOPIC,
    data: { questions: value },
  };
};

export const clearQuestionForTopic = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_QUESTIONS_FOR_TOPIC,
  };
};

export const setSubmittedTopic = (value: string): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_QUESTIONS_FOR_TOPIC,
    data: { submittedTopic: value },
  };
};

export const setGeneratedAnswer = (value: GeneratedAnswer): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_GENERATED_ANSWER,
    data: { answers: [value] },
  };
};

export const setGeneratedAnswerLoading = (inProgress = true): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: inProgress ? ACTIONS.SET_GENERATED_ANSWER_PENDING : ACTIONS.SET_GENERATED_ANSWER_COMPLETED,
  };
};

export const setGeneratedAnswerError = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_GENERATED_ANSWER_ERROR,
  };
};

export const setNoMoreAnswers = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_NO_MORE_ANSWERS,
  };
};

export const setSubmittedInputs = (
  data: Pick<AiGeneratedAnalysisSignals, 'submittedAnchors' | 'submittedCompanies' | 'submittedQuestion'>
): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_SUBMITTED_INPUTS,
    data,
  };
};

export const clearSubmittedInputs = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_SUBMITTED_INPUTS,
  };
};

export const clearGeneratedAnswer = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_GENERATED_ANSWERS,
  };
};

export const setResultOpenAiError = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.SET_RESULTS_OPEN_AI_ERROR,
  };
};

export const clearResultOpenAiError = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_RESULTS_OPEN_AI_ERROR,
  };
};
