import {
  Actions,
  FETCH_PROJECTS,
  PROJECTS_COUNT,
  ProjectDetails,
  ProjectsState,
  REQUEST,
  SELECT_PROJECT,
} from '../types/project';

export const initialProjectDetails: ProjectDetails = {
  ansoffQuadrant: '',
  created: '',
  isActive: false,
  members: [],
  name: '',
  ownerId: { name: '', _id: '' },
  project_tags: [],
  _id: '',
};

const initialState: ProjectsState = {
  isFetching: false,
  projects: [],
  selectedProject: initialProjectDetails,
  projectCount: 0,
};

const Projects = (state = initialState, actions: Actions) => {
  switch (actions.type) {
    case REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PROJECTS_COUNT:
      return {
        ...state,
        isFetching: actions.data ? true : false,
        projectCount: actions.data,
      };
    case FETCH_PROJECTS:
      return {
        ...state,
        isFetching: false,
        projects: actions.data.data,
      };
    case SELECT_PROJECT:
      return {
        ...state,
        selectedProject: actions.data,
      };
    default:
      return state;
  }
};
export default Projects;
