import { ACTIONS, AnalyzeInternalDataAction, AnalyzeInternalDataState } from '../../types';

const initialState: AnalyzeInternalDataState = {
  loading: false,
  insights: [],
  context: '',
  data: '',
  instruction: '',
};

export const reducer = (
  state = initialState,
  action: AnalyzeInternalDataAction<AnalyzeInternalDataState>
): AnalyzeInternalDataState => {
  switch (action.type) {
    case ACTIONS.CLEAR_STATE:
      return initialState;
    case ACTIONS.INSIGHTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.INSIGHTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.INSIGHTS_SUCCESS:
      return { ...state, insights: [...state.insights, ...(action.data?.insights ?? [])] };
    case ACTIONS.SET_FORM_DATA:
      return {
        ...initialState,
        data: action.data?.data ?? '',
        context: action.data?.context ?? '',
        instruction: action.data?.instruction ?? '',
      };
    case ACTIONS.SET_INSTRUCTION:
      return {
        ...state,
        instruction: action.data?.instruction ?? '',
      };
    default:
      return state;
  }
};
