import { ThemeNames } from '../../../common/types/common';
import { ViewType } from '../../AdminTool/types';
import { REQUEST_USER_SIGNIN, SET_USER_DETAILS, SIGNIN_USER_SUCCESS_FAILURE } from '../actions/signin';
import { SET_USER_ID } from '../types/signin';
import { SET_USER_PLANS } from '../types/user';

interface Actions {
  type: string;
  data: any;
}

interface TutorialStatus {
  playgroundForm?: boolean;
  playgroundTopic?: boolean;
  playgroundResult?: boolean;
  playgroundHistory?: boolean;
  scenariosEmptyList?: boolean;
  scenariosListCardView?: boolean;
  scenariosListDiagramView?: boolean;
  scenarioEmptyCanvas?: boolean;
  scenarioNewCanvas?: boolean;
  scenarioExistingCanvas?: boolean;
  questionEmptyCanvas?: boolean;
  questionCanvas?: boolean;
  questionAggregated?: boolean;
  questionEmptyAggregated?: boolean;
  questionValueChain?: boolean;
  questionEmptyValueChain?: boolean;
}

export interface UserDetails {
  [key: string]: any;
  name?: string;
  tutorials?: TutorialStatus;
  appearanceColor?: ThemeNames;
  appearanceViewType?: ViewType;
}

export interface AuthenticationState {
  isFetching: boolean;
  signinSuccessFailure?: object | undefined;
  userId?: string;
  userDetails?: UserDetails;
  userPlans?: object;
}

const initialState: AuthenticationState = {
  isFetching: false,
  signinSuccessFailure: undefined,
  userId: '',
  userDetails: undefined,
  userPlans: {},
};

const Authentication = (state = initialState, actions: Actions): AuthenticationState => {
  switch (actions.type) {
    case REQUEST_USER_SIGNIN:
      return {
        ...state,
        isFetching: true,
      };
    case SIGNIN_USER_SUCCESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        signinSuccessFailure: actions.data,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: actions.data,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: actions.data,
      };
    case SET_USER_PLANS:
      return {
        ...state,
        userPlans: actions.data,
      };
    default:
      return state;
  }
};

export default Authentication;
