import { ACTIONS, Insight, InsightsState, ScenariosAnalysisAction } from '../../types';

export const clearQaInsights = (): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.CLEAR_QA_INSIGHTS,
  };
};

export const qaInsightsInProgress = (inProgress = true): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: inProgress ? ACTIONS.QA_INSIGHTS_PENDING : ACTIONS.QA_INSIGHTS_COMPLETED,
  };
};

export const qaInsightsFetched = (insights: Insight[]): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.QA_INSIGHTS_SUCCESS,
    data: {
      insights,
    },
  };
};
