import { DateRangeFilterBody } from '../../../commonComponents/types/dateRangeSelector';
// Redux action constants
export const REQUEST = 'REQUEST';
export const FETCH_PMF_HEATMAP = 'FETCH_PMF_HEATMAP';
export const SET_SELECTED_COMPANY_HEATMAP = 'SET_SELECTED_COMPANY_HEATMAP';
export const SELECTED_PROJECTED_PMF_HEATMAP = 'SELECTED_PROJECTED_PMF_HEATMAP';
export const SELECTED_PROJECTED_PMF_HEATMAP_LABEL = 'SELECTED_PROJECTED_PMF_HEATMAP_LABEL';
export const SELECTED_PROJECTED_PMF_GENRALIZED_HEATMAP = 'SELECTED_PROJECTED_PMF_GENRALIZED_HEATMAP';
export const CLEAR_HEATMAP = 'CLEAR_HEATMAP';
export const CLEAR_SELECTED_COMPANY_HEATMAP = 'CLEAR_SELECTED_COMPANY_HEATMAP';

export interface ProductMarketFitState {
  isFetching: boolean;
  pmfGeneralizedHeatmap: any;
}
export interface Action {
  type: string;
  data: any;
}
export interface HeatMapData {
  x: number;
  y: number;
  value: number;
}

export interface ErrorResult {
  message: string;
  status: number;
}

export interface PMFHeatmapRequestBody extends DateRangeFilterBody {
  project_id: string;
  aspect_ids: any;
  note_tags?: string[];
}

export interface MultipleCompanyHeatmapRequestBody extends DateRangeFilterBody {
  project_id: string;
  aspect_ids: any;
  company_ids?: any;
  company_tags?: string[];
  note_tags?: string[];
}

export interface HeatmapResponseFormate {
  aspect_ids: string[];
  aspect_labels: string[];
  heatmapData: HeatMapData[];
  maxStrength: number;
  xId: string[];
  xLabels: string[];
  yId: string[];
  yLabels: string[];
}
