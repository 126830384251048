import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import App from './App';
import { ThemeNames } from './common/types/common';
import { ReduxState } from './reducers';
import { SCROLLBAR_STYLES, darkBlueTheme, skyBlueTheme } from './themes';
import { getTheme } from './utils';

interface ThemedAppProps {
  isServer?: boolean;
}

const THEMES: Record<ThemeNames, ThemeOptions> = {
  DARK_BLUE: darkBlueTheme,
  SKY_BLUE: skyBlueTheme,
} as const;

const ThemedApp = ({ isServer }: ThemedAppProps) => {
  const {
    Authentication: { userDetails },
  } = useSelector((state: ReduxState) => state);

  const selectedTheme: ThemeNames = getTheme(userDetails);

  const theme = useMemo(
    () =>
      createTheme({
        ...THEMES[selectedTheme],
        ...SCROLLBAR_STYLES(selectedTheme),
      }),
    [selectedTheme]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isServer ? <App /> : <Route path="/" component={App} />}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemedApp;
