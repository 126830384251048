import { combineReducers } from 'redux';

import { AIGeneratedAnswersAction, AiGeneratedAnalysisState } from '../types';

import { inputsReducer, signalsReducer } from './reducers';

export * from './actionsGenerators';

const aIGeneratedAnalysisReducer = combineReducers<AiGeneratedAnalysisState>({
  inputs: inputsReducer,
  signals: signalsReducer,
});

const aIGeneratedAnalysis = (
  state?: AiGeneratedAnalysisState,
  action?: AIGeneratedAnswersAction<AiGeneratedAnalysisState>
) => {
  return aIGeneratedAnalysisReducer(state, action as any);
};

export default aIGeneratedAnalysis;
