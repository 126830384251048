import { ACTIONS, ScenariosAnalysisAction, UserInputInsightsState } from '../../types';

const initialState: UserInputInsightsState = {
  loading: false,
  signals: [],
  insights: [],
  submittedQuestion: '',
};

export const reducer = (
  state = initialState,
  action: ScenariosAnalysisAction<UserInputInsightsState>
): UserInputInsightsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;
    case ACTIONS.CLEAR_USER_INPUT_INSIGHTS:
      return initialState;
    case ACTIONS.USER_INPUT_INSIGHTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.USER_INPUT_INSIGHTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.USER_INPUT_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: [...state.insights, ...(action.data?.insights ?? [])],
        submittedQuestion: action.data?.submittedQuestion ?? '',
      };
    case ACTIONS.USER_INPUT_SIGNALS_SUCCESS:
      return {
        ...state,
        signals: [...state.signals, ...(action.data?.signals ?? [])],
        paginationToken: action.data?.paginationToken,
      };
    default:
      return state;
  }
};
