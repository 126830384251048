import { FilterOption } from './filter';
import { AnchorInitialState } from './searchAnchors';
import { NoteList } from './searchNotes';
import { ScenarioInitialState } from './searchScenario';
import { SignalInitialState } from './searchSignals';

export const apiPrefix = 'api/v2/search/';

export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_SEARCH_PHARSE = 'SET_SEARCH_PHARSE';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export interface SearchState {
  projectId: string;
  searchPhrase: string;
  noteList: NoteList;
  signal: SignalInitialState;
  anchor: AnchorInitialState;
  scenario: ScenarioInitialState;
}

export interface CommonComponentProps {
  projectId: string;
  searchPhrase: string;
  filterOptionResponse: (list: FilterOption[]) => void;
  selectedFilter: string[];
  toggleSnackbar: (msg: string, status: boolean) => void;
  searchType: string;
  resultCount: number;
  selectedCompanyFilterTags?: string[];
}

export interface SearchResultComponentProps {
  projectId: string;
  history: any;
  toggleSnackbar: (msg: string, status: boolean) => void;
}
