import { ACTIONS, Scenario, ScenariosAnalysisAction, ScenariosState } from '../../types';

export const clearScenarios = (): ScenariosAnalysisAction<ScenariosState> => {
  return {
    type: ACTIONS.CLEAR_SCENARIOS,
  };
};

export const scenariosInProgress = (inProgress = true): ScenariosAnalysisAction<ScenariosState> => {
  return {
    type: inProgress ? ACTIONS.SCENARIOS_PENDING : ACTIONS.SCENARIOS_COMPLETED,
  };
};

export const scenariosFetched = (scenarios: Scenario[]): ScenariosAnalysisAction<ScenariosState> => {
  return {
    type: ACTIONS.SCENARIOS_SUCCESS,
    data: {
      scenarios,
    },
  };
};

export const clearSelectedScenario = (): ScenariosAnalysisAction<ScenariosState> => {
  return {
    type: ACTIONS.CLEAR_SELECTED_SCENARIO,
  };
};

export const setSelectedScenario = (selected: string): ScenariosAnalysisAction<ScenariosState> => {
  return {
    type: ACTIONS.SET_SELECTED_SCENARIO,
    data: { selected },
  };
};
