export const SET_SCENARIO_FILTER_TAGS = 'SET_SCENARIO_FILTER_TAGS';
export const UPDATE_SCENARIO_FILTER_TAGS = 'UPDATE_SCENARIO_FILTER_TAGS';

export interface ScenarioTagsReqBody {
  projectId: string;
  scenario_id: string;
  tags: string[];
}

export interface ScenarioTagsListResponse {
  isSuccess: boolean;
  status: number;
  tags?: string[];
}

export interface TagObj {
  label: string;
  isSelected: boolean;
}
