export const SET_COMPANY_TAGS_LIST = 'SET_COMPANY_TAGS_LIST';
export const UPDATE_COMPANY_TAGS_LIST = 'UPDATE_COMPANY_TAGS_LIST';
export const SET_SCENARIO_SETTINGS = 'SET_SCENARIO_SETTINGS';
export const CLEAR_SCENARIO_SETTINGS = 'CLEAR_SCENARIO_SETTINGS';

export interface FetchEcosystemCompanyTagsRespons {
  isSuccess: boolean;
  status: number;
  data?: {
    id: string;
    tags: string[] | '';
  };
}
export interface SaveEcosystemCompanyTagsBody {
  tag: string;
  projectId: string;
}
export interface SaveEcosystemCompanyTagsResponse {
  isSuccess: boolean;
  status: number;
  message: string;
}

export interface ScenarioSettingsParams {
  decayFactor?: number;
  activationThreshold?: number;
  activationThresholdDecay?: number;
}

export interface GlobalSettingsState {
  isFetching: boolean;
  companyTags: any[];
  scenarioAvailableTags: any[];
  notesAvailableTags: any[];
  scenarioSettings: ScenarioSettingsParams;
}

export interface AddNewCompanyTagsBody {
  tags: string[];
  company_id: string;
  projectId: string;
}

export interface GetCompanyTagsBody {
  company_id: string;
  projectId: string;
}
