import { ACTIONS, ScenariosAnalysisAction, SignalsState } from '../../types';

const initialState: SignalsState = {
  signals: [],
  loading: false,
};

export const reducer = (state = initialState, action: ScenariosAnalysisAction<SignalsState>): SignalsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_SIGNALS:
      return initialState;
    case ACTIONS.SIGNALS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SIGNALS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SIGNALS_SUCCESS:
      return {
        ...state,
        signals: [...state.signals, ...(action.data?.signals ?? [])],
        paginationToken: action.data?.paginationToken,
      };
    default:
      return state;
  }
};
