import { ACTIONS, Insight, InsightsState, ScenariosAnalysisAction } from '../../types';

export const clearMarketResearchInsights = (): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.CLEAR_MARKET_RESEARCH_INSIGHTS,
  };
};

export const marketResearchInsightsInProgress = (inProgress = true): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: inProgress ? ACTIONS.MARKET_RESEARCH_INSIGHTS_PENDING : ACTIONS.MARKET_RESEARCH_INSIGHTS_COMPLETED,
  };
};

export const marketResearchInsightsFetched = (insights: Insight[]): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.MARKET_RESEARCH_INSIGHTS_SUCCESS,
    data: {
      insights,
    },
  };
};
