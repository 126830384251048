export const PUBSUB_CONNECTED = 'PUBSUB_CONNECTED';
export const NEW_MESSAGE_RECEIVED = 'NEW_MESSAGE_RECEIVED';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';

export interface Message {
  event: string;
  project: string;
  timeStamp: number;
}
export interface Connection {
  connectionId: string;
  event: string;
  type: string;
  userId: string;
  timeStamp: number;
}
export interface PubSubStates {
  connection: Connection;
  newMessage: Message;
  messages: Message[];
}
export interface Action {
  type: string;
  data: any;
}
