import { CacheProvider } from '@emotion/react';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ThemedApp from './ThemedApp';
import createEmotionCache from './createEmotionCache';
import configureStore from './store';

const cache = createEmotionCache();

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = JSON.parse(JSON.stringify((window as any).__PRELOADED_STATE__));
// Allow the passed state to be garbage-collected
delete (window as any).__PRELOADED_STATE__;
const store = configureStore(preloadedState as any);

// Load all components needed before rendering
hydrate(
  <CacheProvider value={cache}>
    <Provider store={store}>
      {/* @ts-ignore */}
      <BrowserRouter>
        <ThemedApp />
      </BrowserRouter>
    </Provider>
  </CacheProvider>,
  document.getElementById('root'),
  () => {
    const jssStyles: any = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }
);

// Add hot reload
if (module.hot) {
  module.hot.accept();
}
