export const FETCH_SEARCH_SIGNAL_FILTER_COMPANY = 'FETCH_SEARCH_SIGNAL_FILTER_COMPANY';

export const SET_SIGNAL_FILTERS = 'SET_SIGNAL_FILTERS';
export const SET_SIGNAL_COUNT = 'SET_SIGNAL_COUNT';
export const SET_SIGNAL_RESULTS = 'SET_SIGNAL_RESULTS';

export interface SearchSignalFilterRequestBody {
  search_phrase: string;
  projectId?: string;
}

export interface SearchSignalCountRequestBody {
  company_ids?: string[];
  search_phrase: string;
  projectId?: string;
  company_tags?: string[];
}

export interface SearchSignalCountResponse {
  isSuccess: boolean;
  status: number;
  message?: string;
  signals_count: number;
}

export interface SearchSignalRequestBody {
  company_ids?: string[];
  search_phrase: string;
  page_number: number;
  page_size: number;
  projectId?: string;
  sort_by?: string;
  ascending?: boolean;
  company_tags?: string[];
}

export interface SearchSignalsState {
  signalFilterList: any[];
  anchorFilterList: any[];
  anchorList: any[];
  anchorCount: any[];
}

export interface SignalInitialState {
  filters: any[];
  count: number;
  results: any[];
}
