import { ACTIONS, AIGeneratedAnswersAction, AiGeneratedAnalysisSignals } from '../../types';

const initialState: AiGeneratedAnalysisSignals = {
  anchors: [],
  frame: [],
  companies: [],
  questions: [],
  answers: [],
  submittedTopic: '',
  answerLoading: false,
  answerError: false,
  noMoreAnswers: false,
  openAiError: false,
};

export const signalsReducer = (
  state = initialState,
  action: AIGeneratedAnswersAction<AiGeneratedAnalysisSignals>
): AiGeneratedAnalysisSignals => {
  switch (action.type) {
    case ACTIONS.SET_FRAME:
      return { ...state, ...action.data };
    case ACTIONS.SET_ANCHORS_FOR_TOPIC:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_ANCHORS_FOR_TOPIC:
      return { ...state, anchors: initialState.anchors };
    case ACTIONS.SET_COMPANIES_FOR_TOPIC:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_COMPANIES_FOR_TOPIC:
      return { ...state, companies: initialState.companies };
    case ACTIONS.SET_QUESTIONS_FOR_TOPIC:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_QUESTIONS_FOR_TOPIC:
      return { ...state, questions: initialState.questions };
    case ACTIONS.SET_SUBMITTED_TOPIC:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_GENERATED_ANSWERS:
      return { ...state, answers: initialState.answers, answerError: false, noMoreAnswers: false };
    case ACTIONS.SET_GENERATED_ANSWER:
      return { ...state, answers: [...state.answers, ...(action.data?.answers ?? [])] };
    case ACTIONS.SET_GENERATED_ANSWER_PENDING:
      return { ...state, answerLoading: true };
    case ACTIONS.SET_GENERATED_ANSWER_COMPLETED:
      return { ...state, answerLoading: false };
    case ACTIONS.SET_GENERATED_ANSWER_ERROR:
      return { ...state, answerError: true };
    case ACTIONS.SET_NO_MORE_ANSWERS:
      return { ...state, noMoreAnswers: true };
    case ACTIONS.SET_SUBMITTED_INPUTS:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_SUBMITTED_INPUTS:
      return { ...state, submittedAnchors: undefined, submittedCompanies: undefined, submittedQuestion: undefined };
    case ACTIONS.SET_RESULTS_OPEN_AI_ERROR:
      return { ...state, openAiError: true };
    case ACTIONS.CLEAR_RESULTS_OPEN_AI_ERROR:
      return { ...state, openAiError: false };
    case ACTIONS.CLEAR_AI_GENERATED_ANSWERS:
      return initialState;
    default:
      return state;
  }
};
