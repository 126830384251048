import { ACTIONS, Insight, ScenariosAnalysisAction, Signal, UserInputInsightsState } from '../../types';

export const clearUserInputInsights = (): ScenariosAnalysisAction<UserInputInsightsState> => {
  return {
    type: ACTIONS.CLEAR_USER_INPUT_INSIGHTS,
  };
};

export const userInputInsightsInProgress = (inProgress = true): ScenariosAnalysisAction<UserInputInsightsState> => {
  return {
    type: inProgress ? ACTIONS.USER_INPUT_INSIGHTS_PENDING : ACTIONS.USER_INPUT_INSIGHTS_COMPLETED,
  };
};

export const userInputInsightsFetched = (
  insights: Insight[],
  submittedQuestion: string
): ScenariosAnalysisAction<UserInputInsightsState> => {
  return {
    type: ACTIONS.USER_INPUT_INSIGHTS_SUCCESS,
    data: {
      insights,
      submittedQuestion,
    },
  };
};

export const userInputSignalsFetched = (
  signals: Signal[],
  paginationToken: string
): ScenariosAnalysisAction<UserInputInsightsState> => {
  return {
    type: ACTIONS.USER_INPUT_SIGNALS_SUCCESS,
    data: {
      signals,
      paginationToken,
    },
  };
};
