import { combineReducers } from 'redux';

import { FETCH_COMPANIES, SET_SELECTED_COMPANY, TOTAL_COMPANY_COUNT } from '../types/companyList';
import { Actions, EcosystemDataState } from '../types/ecosystem';
import { CLEAR_SELECTED_COMPANY_HEATMAP, SET_SELECTED_COMPANY_HEATMAP } from '../types/productMarketFit';
import { RecommendedSignalsState } from '../types/recommendedSignal';

import CompanyRecommendation from './recommendedSignal';

const initialState: EcosystemDataState = {
  companies: { isSuccess: false, data: [], message: '', status: 500 },
  selectedCompany: {},
  totalCompanyCount: 0,
  selectedCompanyHeatmapData: [],
};

const EcosystemData = (state = initialState, actions: Actions): EcosystemDataState => {
  switch (actions.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        companies: actions.data,
      };
    case TOTAL_COMPANY_COUNT:
      return {
        ...state,
        totalCompanyCount: actions.data,
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: actions.data,
      };
    case SET_SELECTED_COMPANY_HEATMAP:
      return {
        ...state,
        selectedCompanyHeatmapData: [...state.selectedCompanyHeatmapData, actions.data],
      };
    case CLEAR_SELECTED_COMPANY_HEATMAP:
      return {
        ...state,
        selectedCompanyHeatmapData: [],
      };
    default:
      return state;
  }
};

export interface EcosystemState {
  EcosystemData: EcosystemDataState;
  CompanyRecommendation: RecommendedSignalsState;
}

const EcosystemReducer = combineReducers<EcosystemState>({
  EcosystemData,
  CompanyRecommendation,
});

const Ecosystem = (state?: EcosystemState, action?: Actions) => {
  return EcosystemReducer(state, action as any);
};

export default Ecosystem;
