import { Actions } from '../../Ecosystem/types/ecosystem';
import {
  FETCH_SEARCH_ANCHOR_COUNT,
  FETCH_SEARCH_ANCHOR_FILTERS,
  FETCH_SEARCH_ANCHOR_LIST,
} from '../types/searchAnchors';
import { FETCH_SEARCH_SIGNAL_FILTER_COMPANY, SearchSignalsState } from '../types/searchSignals';

const initialState: SearchSignalsState = {
  signalFilterList: [],
  anchorFilterList: [],
  anchorList: [],
  anchorCount: [],
};
const SearchSignals = (state = initialState, actions: Actions) => {
  switch (actions.type) {
    case FETCH_SEARCH_SIGNAL_FILTER_COMPANY:
      return {
        ...state,
        signalFilterList: actions.data,
      };
    case FETCH_SEARCH_ANCHOR_FILTERS:
      return {
        ...state,
        anchorFilterList: [...state.anchorFilterList, actions.data],
      };
    case FETCH_SEARCH_ANCHOR_LIST:
      return {
        ...state,
        anchorList: actions.data,
      };
    case FETCH_SEARCH_ANCHOR_COUNT:
      return {
        ...state,
        anchorCount: actions.data,
      };
    default:
      return state;
  }
};
export default SearchSignals;
