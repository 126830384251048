export const REQUEST_USER_SIGNIN = 'REQUEST_USER_SIGNIN';
export const SIGNIN_USER_SUCCESS_FAILURE = 'SIGNIN_USER_SUCCESS_FAILURE';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export interface RequestUserSignin {
  type: string;
}

export interface SigninSuccessFailureObj {
  isSuccess: boolean;
  response: unknown;
  message: string;
}

export interface Actions {
  type: string;
  signinSuccessFailure: SigninSuccessFailureObj;
}

export type SigninActionType = RequestUserSignin | Actions;
