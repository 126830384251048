import { AnchorForTopic, CompanyForTopic, GeneratedAnswer } from './resources';

export const ACTIONS = {
  SET_SUBMITTED_TOPIC: 'SET_SUBMITTED_TOPIC',
  SET_SUBMITTED_INPUTS: 'SET_SUBMITTED_INPUTS',
  CLEAR_SUBMITTED_INPUTS: 'CLEAR_SUBMITTED_INPUTS',
  SET_TOPIC_INPUT: 'SET_TOPIC_INPUT',
  SET_TOPIC_PENDING: 'SET_TOPIC_PENDING',
  SET_TOPIC_COMPLETED: 'SET_TOPIC_COMPLETED',
  SET_TOPIC_ERROR: 'SET_TOPIC_ERROR',
  CLEAR_TOPIC_ERROR: 'CLEAR_TOPIC_ERROR',
  CLEAR_ANCHORS_INPUT: 'CLEAR_ANCHORS_INPUT',
  SET_ANCHORS_INPUT: 'SET_ANCHORS_INPUT',
  CLEAR_COMPANIES_INPUT: 'CLEAR_COMPANIES_INPUT',
  SET_COMPANIES_INPUT: 'SET_COMPANIES_INPUT',
  CLEAR_QUESTION_INPUT: 'CLEAR_QUESTION_INPUT',
  SET_QUESTION_INPUT: 'SET_QUESTION_INPUT',
  SET_CUSTOM_QUESTION_INPUT: 'SET_CUSTOM_QUESTION_INPUT',
  CLEAR_CUSTOM_QUESTION_INPUT: 'CLEAR_CUSTOM_QUESTION_INPUT',
  SET_FRAME: 'SET_FRAME',
  SET_ANCHORS_FOR_TOPIC: 'SET_ANCHORS_FOR_TOPIC',
  CLEAR_ANCHORS_FOR_TOPIC: 'CLEAR_ANCHORS_FOR_TOPIC',
  SET_COMPANIES_FOR_TOPIC: 'SET_COMPANIES_FOR_TOPIC',
  CLEAR_COMPANIES_FOR_TOPIC: 'CLEAR_COMPANIES_FOR_TOPIC',
  SET_QUESTIONS_FOR_TOPIC: 'SET_QUESTIONS_FOR_TOPIC',
  CLEAR_QUESTIONS_FOR_TOPIC: 'CLEAR_QUESTIONS_FOR_TOPIC',
  CLEAR_GENERATED_ANSWERS: 'CLEAR_GENERATED_ANSWERS',
  SET_NO_MORE_ANSWERS: 'SET_NO_MORE_ANSWERS',
  SET_GENERATED_ANSWER: 'SET_GENERATED_ANSWER',
  SET_GENERATED_ANSWER_PENDING: 'SET_GENERATED_ANSWER_PENDING',
  SET_GENERATED_ANSWER_COMPLETED: 'SET_GENERATED_ANSWER_COMPLETED',
  SET_GENERATED_ANSWER_ERROR: 'SET_GENERATED_ANSWER_ERROR',
  CLEAR_AI_GENERATED_ANSWERS: 'CLEAR_AI_GENERATED_ANSWERS',
  SET_FORM_OPEN_AI_ERROR: 'SET_FORM_OPEN_AI_ERROR',
  CLEAR_FORM_OPEN_AI_ERROR: 'CLEAR_FORM_OPEN_AI_ERROR',
  SET_RESULTS_OPEN_AI_ERROR: 'SET_RESULTS_OPEN_AI_ERROR',
  CLEAR_RESULTS_OPEN_AI_ERROR: 'CLEAR_RESULTS_OPEN_AI_ERROR',
} as const;

export type ActionsType = keyof typeof ACTIONS;

export interface AIGeneratedAnswersAction<T> {
  type: ActionsType;
  data?: Partial<T>;
}

export interface AiGeneratedAnalysisInputs {
  topicInput: string;
  anchorsInput: string[];
  companiesInput: string[];
  questionInput: string;
  customQuestionInput: string;
  topicLoading: boolean;
  topicError: boolean;
  openAiError: boolean;
}

export interface AiGeneratedAnalysisSignals {
  submittedTopic: string;
  submittedAnchors?: string[];
  submittedCompanies?: string[];
  submittedQuestion?: string;
  frame: string[];
  questions: string[];
  anchors: AnchorForTopic[];
  companies: CompanyForTopic[];
  answers: GeneratedAnswer[];
  answerLoading: boolean;
  answerError: boolean;
  noMoreAnswers: boolean;
  openAiError: boolean;
}

export interface AiGeneratedAnalysisState {
  inputs: AiGeneratedAnalysisInputs;
  signals: AiGeneratedAnalysisSignals;
}
