import { red } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';

import colors from '../constants/colors';

export const darkBlueTheme: ThemeOptions = {
  palette: {
    primary: {
      main: colors.primaryBlue,
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: '"Montserrat", Roboto, PT Sans, Arial, Helvetica, sans-serif',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
  },
};
