export enum ACTIONS {
  'RESEARCH_PLAN_PENDING' = 'RESEARCH_PLAN_PENDING',
  'RESEARCH_PLAN_COMPLETED' = 'RESEARCH_PLAN_COMPLETED',
  'RESEARCH_PLAN_SUCCESS' = 'RESEARCH_PLAN_SUCCESS',
  'RESEARCH_PLAN_FAILED' = 'RESEARCH_PLAN_FAILED',

  'RESEARCH_PLAN_PARTS_PENDING' = 'RESEARCH_PLAN_PARTS_PENDING',
  'RESEARCH_PLAN_PARTS_COMPLETED' = 'RESEARCH_PLAN_PARTS_COMPLETED',
  'RESEARCH_PLAN_PARTS_SUCCESS' = 'RESEARCH_PLAN_PARTS_SUCCESS',
  'RESEARCH_PLAN_PARTS_FAILED' = 'RESEARCH_PLAN_PARTS_FAILED',

  'SOURCES_PENDING' = 'SOURCES_PENDING',
  'SOURCES_COMPLETED' = 'SOURCES_COMPLETED',
  'SOURCES_SUCCESS' = 'SOURCES_SUCCESS',
  'SOURCES_FAILED' = 'SOURCES_FAILED',

  'INTERNAL_SOURCES_PENDING' = 'INTERNAL_SOURCES_PENDING',
  'INTERNAL_SOURCES_COMPLETED' = 'INTERNAL_SOURCES_COMPLETED',
  'INTERNAL_SOURCES_SUCCESS' = 'INTERNAL_SOURCES_SUCCESS',
  'INTERNAL_SOURCES_FAILED' = 'INTERNAL_SOURCES_FAILED',

  'OVERVIEW_PENDING' = 'OVERVIEW_PENDING',
  'OVERVIEW_COMPLETED' = 'OVERVIEW_COMPLETED',
  'OVERVIEW_SUCCESS' = 'OVERVIEW_SUCCESS',
  'OVERVIEW_FAILED' = 'OVERVIEW_FAILED',

  'COMPANIES_PENDING' = 'COMPANIES_PENDING',
  'COMPANIES_COMPLETED' = 'COMPANIES_COMPLETED',
  'COMPANIES_SUCCESS' = 'COMPANIES_SUCCESS',
  'COMPANIES_FAILED' = 'COMPANIES_FAILED',

  'DETAILED_ANALYSIS_PENDING' = 'DETAILED_ANALYSIS_PENDING',
  'DETAILED_ANALYSIS_COMPLETED' = 'DETAILED_ANALYSIS_COMPLETED',
  'DETAILED_ANALYSIS_SUCCESS' = 'DETAILED_ANALYSIS_SUCCESS',
  'DETAILED_ANALYSIS_FAILED' = 'DETAILED_ANALYSIS_FAILED',

  'INSIGHTS_PENDING' = 'INSIGHTS_PENDING',
  'INSIGHTS_COMPLETED' = 'INSIGHTS_COMPLETED',
  'INSIGHTS_SUCCESS' = 'INSIGHTS_SUCCESS',
  'INSIGHTS_FAILED' = 'INSIGHTS_FAILED',

  'CURRENT_MARKET_PENDING' = 'CURRENT_MARKET_PENDING',
  'CURRENT_MARKET_COMPLETED' = 'CURRENT_MARKET_COMPLETED',
  'CURRENT_MARKET_SUCCESS' = 'CURRENT_MARKET_SUCCESS',
  'CURRENT_MARKET_FAILED' = 'CURRENT_MARKET_FAILED',

  'MARKET_GROWTH_PENDING' = 'MARKET_GROWTH_PENDING',
  'MARKET_GROWTH_COMPLETED' = 'MARKET_GROWTH_COMPLETED',
  'MARKET_GROWTH_SUCCESS' = 'MARKET_GROWTH_SUCCESS',
  'MARKET_GROWTH_FAILED' = 'MARKET_GROWTH_FAILED',

  'SUGGESTED_QUESTIONS_PENDING' = 'SUGGESTED_QUESTIONS_PENDING',
  'SUGGESTED_QUESTIONS_COMPLETED' = 'SUGGESTED_QUESTIONS_COMPLETED',
  'SUGGESTED_QUESTIONS_SUCCESS' = 'SUGGESTED_QUESTIONS_SUCCESS',
  'SUGGESTED_QUESTIONS_FAILED' = 'SUGGESTED_QUESTIONS_FAILED',

  'CLEAR_ANALYSIS' = 'CLEAR_ANALYSIS',
}

export interface PlaygroundAnalysisAction<T> {
  type: ACTIONS;
  payload?: Partial<T>;
}
