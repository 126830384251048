import { Actions } from '../../Ecosystem/types/ecosystem';
import {
  CLEAR_SCENARIO_SETTINGS,
  GlobalSettingsState,
  SET_COMPANY_TAGS_LIST,
  SET_SCENARIO_SETTINGS,
  UPDATE_COMPANY_TAGS_LIST,
} from '../types/ecosystemSettingsCompanyTags';
import { SET_NOTE_AVAILABLE_TAGS, UPDATE_NOTE_AVAILABLE_TAGS } from '../types/noteTags';
import { SET_SCENARIO_AVAILABLE_TAGS, UPDATE_SCENARIO_AVAILABLE_TAGS } from '../types/scenarioTags';

const initialState: GlobalSettingsState = {
  isFetching: false,
  companyTags: [],
  scenarioAvailableTags: [],
  notesAvailableTags: [],
  scenarioSettings: {},
};

const GlobalSettings = (state = initialState, actions: Actions): GlobalSettingsState => {
  switch (actions.type) {
    case SET_COMPANY_TAGS_LIST:
      return {
        ...state,
        companyTags: [...state.companyTags, actions.data],
      };
    case UPDATE_COMPANY_TAGS_LIST:
      return {
        ...state,
        companyTags: actions.data,
      };
    case SET_SCENARIO_AVAILABLE_TAGS:
      return {
        ...state,
        scenarioAvailableTags: [...state.scenarioAvailableTags, actions.data],
      };
    case UPDATE_SCENARIO_AVAILABLE_TAGS:
      return {
        ...state,
        scenarioAvailableTags: actions.data,
      };
    case SET_NOTE_AVAILABLE_TAGS:
      return {
        ...state,
        notesAvailableTags: [...state.notesAvailableTags, actions.data],
      };
    case UPDATE_NOTE_AVAILABLE_TAGS:
      return {
        ...state,
        notesAvailableTags: actions.data,
      };
    case SET_SCENARIO_SETTINGS:
      return {
        ...state,
        scenarioSettings: actions.data,
      };
    case CLEAR_SCENARIO_SETTINGS:
      return {
        ...state,
        scenarioSettings: initialState.scenarioSettings,
      };
    default:
      return state;
  }
};

export default GlobalSettings;
