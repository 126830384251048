import { ACTIONS, AspectsState, ScenariosAnalysisAction } from '../../types';

const initialState: AspectsState = {
  loading: false,
  aspects: [],
};

export const reducer = (
  state = initialState,
  action: ScenariosAnalysisAction<AspectsState & { aspectId: string }>
): AspectsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_ASPECTS:
      return initialState;
    case ACTIONS.CLEAR_SELECTED_ASPECTS:
      return { ...state, selectedAspects: undefined };
    case ACTIONS.SET_SELECTED_ASPECTS:
      return {
        ...state,
        selectedAspects: {
          ...state.selectedAspects,
          ...(action.data?.aspectId ? { [action.data.aspectId]: !state?.[action.data.aspectId] } : {}),
        },
      };
    case ACTIONS.ASPECTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.ASPECTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.ASPECTS_SUCCESS:
      return {
        ...state,
        aspects: action.data?.aspects ?? [],
        selectedAspects: (action.data?.aspects ?? []).reduce((prev, { id }) => {
          return { ...prev, [id]: false };
        }, {} as Record<string, boolean>),
      };
    default:
      return state;
  }
};
