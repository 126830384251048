import { ACTIONS, ScenariosAnalysisAction, Signal, SignalsState } from '../../types';

export const clearSignals = (): ScenariosAnalysisAction<SignalsState> => {
  return {
    type: ACTIONS.CLEAR_SIGNALS,
  };
};

export const signalsInProgress = (inProgress = true): ScenariosAnalysisAction<SignalsState> => {
  return {
    type: inProgress ? ACTIONS.SIGNALS_PENDING : ACTIONS.SIGNALS_COMPLETED,
  };
};

export const signalsFetched = (signals: Signal[], paginationToken: string): ScenariosAnalysisAction<SignalsState> => {
  return {
    type: ACTIONS.SIGNALS_SUCCESS,
    data: {
      signals,
      paginationToken,
    },
  };
};
