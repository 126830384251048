export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const RETRIVE_LOGIN_DETAILS = 'RETRIVE_LOGIN_DETAILS';
export const HISTORY = 'HISTORY';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const SET_APP_UPDATE_MODAL = 'SET_APP_UPDATE_MODAL';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

export interface User {
  isSuccess: boolean;
  message: string;
  response: object;
}
export interface AppState {
  isAuthenticated: boolean;
  user: any;
  token: string;
  history: any;
  redirectUrl: string;
  leftDrawerExpanded: boolean;
  drawerWidth: number;
}

export interface CommonApiResp {
  isSuccess: boolean;
  status: number;
  message: string;
}
export interface CommonApiError {
  isSuccess: boolean;
  status: number;
  message: string;
}

export interface ReduxAction<T = unknown> {
  type: string;
  data: T;
}
