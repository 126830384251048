export const SET_SCENARIO_AVAILABLE_TAGS = 'SET_SCENARIO_AVAILABLE_TAGS';
export const UPDATE_SCENARIO_AVAILABLE_TAGS = 'UPDATE_SCENARIO_AVAILABLE_TAGS';

export interface ScenarioTagsListResponse {
  isSuccess: boolean;
  status: number;
  available_tags: any;
}

export interface AddRemoveReqBody {
  tags: any;
  projectId?: string;
}

export interface AddRemoveResponse {
  isSuccess: boolean;
  status: number;
  updated_available_tags: string[];
}
