import Ecosystem from '../reducers/ecosystem';
export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const REQUEST = 'REQUEST';
export const TOTAL_COMPANY_COUNT = 'TOTAL_COMPANY_COUNT';
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';
export interface CompanyResult {
  status: number;
  data: { data: [] };
  statusText: string;
}
export interface SearchCompanyResult {
  status: number;
  data: object[];
  statusText: string;
}
export interface CompanyCountResult {
  status: number;
  data: number;
  statusText: string;
}

export interface Company {
  isSuccess: boolean;
  data: object[];
  message: string;
  status: number;
}

export interface CompanyCount {
  isSuccess: boolean;
  data: number;
  status: number;
}

export interface RowData {
  name: string;
  homepage: string;
  total_signals: number | string;
  value_proposition: number | string;
  customer_segments: number | string;
  key_partners: number | string;
  features: number | string;
  no_of_mentions: number | string;
  revenue: number | string;
  no_of_emp: number | string;
  last_funding: number | string;
  last_funding_on: number | string;
  pre_money_valuation: number | string;
  signal_change_percent: number | string;
  rank: number | string;
  no_of_signals: any;
  _id: string;
  '#': number;
  is_tagged: boolean;
  no_of_recommended_anchors: number | 0;
  activated_on: string | null;
  company_id?: string;
  company?: string;
  funding?: any;
  description?: string;
  traction?: number;
  no_of_evidences?: number;
  no_of_matched_anchors: string | number;
  company_mirror?: {
    homepage?: string;
    name?: string;
  };
}
export interface Row {
  name: string;
  homepage: string;
  total_signals: number | string;
  no_of_signals: {
    value_proposition: number | string;
    customer_segments: number | string;
    key_partners: number | string;
    features: number | string;
  };
  no_of_mentions: number | string;
  revenue: number | string;
  no_of_emp: number | string;
  last_funding: number | string;
  last_funding_on: number | string;
  pre_money_valuation: number | string;
  signal_change_percent: number | string;
  rank: number | string;
  '#': number;
  no_of_matched_anchors: string | number;
}
export interface Column {
  title: string | React.ReactElement<any> | React.Component<any>;
  field: string;
  key?: string;
  width?: number;
  align?: 'center';
  sorting?: boolean;
  render?: (data: Row) => React.ReactElement<any> | string;
  sortingActive?: boolean;
}
export interface RequestBody {
  projectId: string;
  page: number;
  pageSize: number;
  search?: string;
  sortBy?: string;
  tags?: string;
  ascending?: boolean;
}
export interface EcosystemSearchRequestBody {
  projectId: string;
  page_number: number;
  page_size?: number;
  search: string;
  tags: string;
}
export interface ExportRequestBody {
  projectId: string;
  email: string;
  projectName: string;
}
export interface TagUntagRequestBody {
  companyId: string;
  is_tagged: boolean;
  project_id: string;
}
export interface CompanyData {
  '#': string;
  name: string;
  tag: React.ReactElement<any>;
  homepage: React.ReactElement<any>;
  total_signals: string | number;
  value_proposition: string | number;
  customer_segments: string | number;
  key_partners: string | number;
  features: string | number;
  no_of_mentions: string | number;
  revenue: string | number;
  no_of_emp: string | number;
  last_funding: string | number;
  last_funding_on: string | number;
  pre_money_valuation: string | number;
  signal_change_percent: string | number;
  rank: string | number;
  id: string | number;
  action: React.ReactElement<any>;
  no_of_matched_anchors: string | number;
}
export interface SelectedCompanyForDelete {
  name: string;
  _id: string;
}

export interface ToggleActiveRequestBody {
  projectId: string;
  active: boolean;
}

export type CompanyActionTypes = CompanyResult;

export interface AnalysisSelectedCompany {
  _id: string;
  name: string;
}
export interface RouteStates {
  page: number;
  pageSize: number;
  sorting: string;
  sortOrder: string;
  tag: string;
}
export interface Sorting {
  id: string;
  order: string;
}
export interface HColumn {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
  fixed: boolean;
  sorting?: boolean;
  left?: number;
  tooltipLabel?: string;
}

export interface EcosystemCountRequestBody {
  projectId: string;
  tags?: string;
  search?: string;
}

export interface EcosystemCountResponse {
  data: number;
  isSuccess: boolean;
  message: string;
  status: number;
}
