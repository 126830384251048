import { combineReducers } from 'redux';

import { ACTIONS, PlaygroundAnalysisAction, SectionsState, StateSectionNames } from '../../types';

const initialState: SectionsState = {
  companies: { data: [], error: false, loading: false },
  insights: { data: [], error: false, loading: false },
  research_plan: { data: { instructions: [] }, error: false, loading: false },
  market_growth: { data: {}, error: false, loading: false },
  current_market: { data: {}, error: false, loading: false },
  sources: { data: [], error: false, loading: false },
  internal_sources: { data: [], error: false, loading: false },
  overview: { data: {}, error: false, loading: false },
  detailed_analysis: { data: [], error: false, loading: false },
  suggested_questions: { data: [], error: false, loading: false },
  research_plan_parts: { data: { entities: {}, value_chain_phrases: {} }, error: false, loading: false },
};

const companiesReducer = (
  state = initialState.companies,
  action: PlaygroundAnalysisAction<SectionsState['companies']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.companies;
    case ACTIONS.COMPANIES_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.COMPANIES_FAILED:
      return { ...initialState.companies, error: true };
    case ACTIONS.COMPANIES_PENDING:
      return { ...state, loading: true };
    case ACTIONS.COMPANIES_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.companies.data,
      };

    default:
      return state;
  }
};

const insightsReducer = (
  state = initialState.insights,
  action: PlaygroundAnalysisAction<SectionsState['insights']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.insights;
    case ACTIONS.INSIGHTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.INSIGHTS_FAILED:
      return { ...initialState.insights, error: true };
    case ACTIONS.INSIGHTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.INSIGHTS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.insights.data,
      };

    default:
      return state;
  }
};

const SuggestedQuestionsReducer = (
  state = initialState.suggested_questions,
  action: PlaygroundAnalysisAction<SectionsState['suggested_questions']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.suggested_questions;
    case ACTIONS.SUGGESTED_QUESTIONS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SUGGESTED_QUESTIONS_FAILED:
      return { ...initialState.suggested_questions, error: true };
    case ACTIONS.SUGGESTED_QUESTIONS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SUGGESTED_QUESTIONS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.suggested_questions.data,
      };

    default:
      return state;
  }
};

const researchPlanReducer = (
  state = initialState.research_plan,
  action: PlaygroundAnalysisAction<SectionsState['research_plan']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.research_plan;
    case ACTIONS.RESEARCH_PLAN_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.RESEARCH_PLAN_FAILED:
      return { ...initialState.research_plan, error: true };
    case ACTIONS.RESEARCH_PLAN_PENDING:
      return { ...state, loading: true };
    case ACTIONS.RESEARCH_PLAN_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.research_plan.data,
      };

    default:
      return state;
  }
};

const researchPlanPartsReducer = (
  state = initialState.research_plan_parts,
  action: PlaygroundAnalysisAction<SectionsState['research_plan_parts']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.research_plan_parts;
    case ACTIONS.RESEARCH_PLAN_PARTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.RESEARCH_PLAN_PARTS_FAILED:
      return { ...initialState.research_plan_parts, error: true };
    case ACTIONS.RESEARCH_PLAN_PARTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.RESEARCH_PLAN_PARTS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.research_plan_parts.data,
      };

    default:
      return state;
  }
};

const currentMarketReducer = (
  state = initialState.current_market,
  action: PlaygroundAnalysisAction<SectionsState['current_market']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.current_market;
    case ACTIONS.CURRENT_MARKET_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.CURRENT_MARKET_FAILED:
      return { ...initialState.current_market, error: true };
    case ACTIONS.CURRENT_MARKET_PENDING:
      return { ...state, loading: true };
    case ACTIONS.CURRENT_MARKET_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.current_market.data,
      };

    default:
      return state;
  }
};

const marketGrowthReducer = (
  state = initialState.market_growth,
  action: PlaygroundAnalysisAction<SectionsState['market_growth']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.market_growth;
    case ACTIONS.MARKET_GROWTH_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.MARKET_GROWTH_FAILED:
      return { ...initialState.market_growth, error: true };
    case ACTIONS.MARKET_GROWTH_PENDING:
      return { ...state, loading: true };
    case ACTIONS.MARKET_GROWTH_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.market_growth.data,
      };

    default:
      return state;
  }
};

const sourcesReducer = (state = initialState.sources, action: PlaygroundAnalysisAction<SectionsState['sources']>) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.sources;
    case ACTIONS.SOURCES_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SOURCES_FAILED:
      return { ...initialState.sources, error: true };
    case ACTIONS.SOURCES_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SOURCES_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.sources.data,
      };

    default:
      return state;
  }
};

const internalSourcesReducer = (
  state = initialState.internal_sources,
  action: PlaygroundAnalysisAction<SectionsState['internal_sources']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.internal_sources;
    case ACTIONS.INTERNAL_SOURCES_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.INTERNAL_SOURCES_FAILED:
      return { ...initialState.internal_sources, error: true };
    case ACTIONS.INTERNAL_SOURCES_PENDING:
      return { ...state, loading: true };
    case ACTIONS.INTERNAL_SOURCES_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.internal_sources.data,
      };

    default:
      return state;
  }
};

const overviewReducer = (
  state = initialState.overview,
  action: PlaygroundAnalysisAction<SectionsState['overview']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.overview;
    case ACTIONS.OVERVIEW_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.OVERVIEW_FAILED:
      return { ...initialState.overview, error: true };
    case ACTIONS.OVERVIEW_PENDING:
      return { ...state, loading: true };
    case ACTIONS.OVERVIEW_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.overview.data,
      };

    default:
      return state;
  }
};

const detailedAnalysisReducer = (
  state = initialState.detailed_analysis,
  action: PlaygroundAnalysisAction<SectionsState['detailed_analysis']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.detailed_analysis;
    case ACTIONS.DETAILED_ANALYSIS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.DETAILED_ANALYSIS_FAILED:
      return { ...initialState.detailed_analysis, error: true };
    case ACTIONS.DETAILED_ANALYSIS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.DETAILED_ANALYSIS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.detailed_analysis.data,
      };

    default:
      return state;
  }
};

const analysisReducer = combineReducers<SectionsState>({
  companies: companiesReducer,
  insights: insightsReducer,
  research_plan: researchPlanReducer,
  market_growth: marketGrowthReducer,
  current_market: currentMarketReducer,
  sources: sourcesReducer,
  overview: overviewReducer,
  detailed_analysis: detailedAnalysisReducer,
  suggested_questions: SuggestedQuestionsReducer,
  internal_sources: internalSourcesReducer,
  research_plan_parts: researchPlanPartsReducer,
});

export const reducer = (
  state = initialState,
  action: PlaygroundAnalysisAction<SectionsState[StateSectionNames]>
): SectionsState => {
  return analysisReducer(state, action);
};
