const colors = {
  primaryBlue: '#143975',
  main: '#143975',
  secondary: '#2e384d',
  yellow: '#FFB900',
  iconRed: '#FF5D5D',
  skyBlue: '#E8F1F7',
  grey1: '#959DAD',
  grey2: '#00000029',
  green: '#8FDA67',
  white: '#fff',
  black: '#000',
  secondaryBlue: '#47A1F7',
  blue: '#47A1F7',
  lightBlue: '#f1f1f1',
  brightSkyBlue: '#47a1f7',
  darkBlue: '#07336F',
  lighterBlue: '#E6F5FF',
  black2: '#1c2029',
  grey3: '#78849E',
  grey: '#F5F5F7',
  darkSkyBlue: '#CEE0F2',
  borderColor: '#D5D5D5',
  black3: '#454F63',
  black4: '#2A2E43',
  greishBlue: '#E6EAF0',
  grey4: '#737373',
  lightPink: '#CDA2AB',
  lightYellow: '#F8C538',
  grey5: '#E1E6ED',
  grey6: '#E4E4E4',
  lightPurple: '#B48DFF',
  grey7: '#0000001A',
  lightBlue2: '#73ADD4',
  grey8: '#f5f5f5',
  grey9: '#e4e4e4',
  blue2: '#69a7e01f',
  grey10: '#2E384D',
  grey11: '#FAFAFA',
  grey12: '#979797',
  errorInput: '#ff1744',
  primary2: '#2F5695',
  darkSkyBlue2: '#cee0f296',
  grey13: '#E4E8F0',
  grey14: '#707070',
  grey16: '#A9A8C1',
  darkGrey: '#43425D',
  grey15: '#908d8d0d',
  grey17: '#b7b3b314',
  navbarGrey: '#7a819085',
  grey18: '#CACED5',
  grey19: '#efefef',
  disabledSelectBg: '#f1f1f1',
  slate_Gray: '#70809033',
  teal: '#00808033',
  pale_turquoise: '#AFEEEE33',
  silver: '#C0C0C033',
  bright_blue: '#009BFF33',
  dark_salmon: '#E9967A33',
  corn_silk: '#FFF8DC33',
  light_coral: '#F0808033',
  olive: '#808000',
  lightOlive: '#B2B457',
  firebrick: '#b22222',
  lightFirebrick: '#c96565',
};

export default colors;
