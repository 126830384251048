import { ACTIONS, InputsState, ScenariosAnalysisAction } from '../../types';

const initialState: InputsState = {
  loading: false,
  selectedAnchors: [],
  selectedCompanies: [],
  anchors: [],
  companies: [],
};

export const reducer = (state = initialState, action: ScenariosAnalysisAction<InputsState>): InputsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_INPUTS:
      return initialState;
    case ACTIONS.CLEAR_SELECTED_ANCHORS:
      return { ...state, selectedAnchors: [] };
    case ACTIONS.CLEAR_SELECTED_COMPANIES:
      return { ...state, selectedCompanies: [] };
    case ACTIONS.SET_SELECTED_ANCHORS:
      return { ...state, selectedAnchors: action.data?.selectedAnchors ?? [] };
    case ACTIONS.SET_SELECTED_COMPANIES:
      return { ...state, selectedCompanies: action.data?.selectedCompanies ?? [] };
    case ACTIONS.INPUTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.INPUTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.ANCHOR_SUCCESS:
      return {
        ...state,
        anchors: action.data?.anchors ?? [],
      };
    case ACTIONS.COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.data?.companies ?? [],
      };
    default:
      return state;
  }
};
