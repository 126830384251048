export const SET_SCENARIO_FILTERS = 'SET_SCENARIO_FILTERS';
export const SET_SCENARIO_COUNT = 'SET_SCENARIO_COUNT';
export const SET_SCENARIO_RESULTS = 'SET_SCENARIO_RESULTS';

export interface SearchScenarioCountRequestBody {
  tags?: string[];
  search_phrase: string;
  projectId?: string;
}

export interface SearchScenarioCountResponse {
  isSuccess: boolean;
  status: number;
  message?: string;
  scenarios_count: number;
}

export interface SearchScenarioRequestBody {
  tags?: string[];
  search_phrase: string;
  page_number: number;
  page_size: number;
  projectId?: string;
}

export interface ScenarioInitialState {
  filters: any[];
  count: number;
  results: any[];
}
