import { ACTIONS, AnalyzeInternalDataAction, AnalyzeInternalDataState, GenerateInsightsReqPayload } from '../../types';

export const clearInsights = (): AnalyzeInternalDataAction<AnalyzeInternalDataState> => {
  return {
    type: ACTIONS.CLEAR_STATE,
  };
};

export const insightsInProgress = (inProgress = true): AnalyzeInternalDataAction<AnalyzeInternalDataState> => {
  return {
    type: inProgress ? ACTIONS.INSIGHTS_PENDING : ACTIONS.INSIGHTS_COMPLETED,
  };
};

export const insightsFetched = (insights: string[]): AnalyzeInternalDataAction<AnalyzeInternalDataState> => {
  return {
    type: ACTIONS.INSIGHTS_SUCCESS,
    data: {
      insights,
    },
  };
};

export const setInputs = (inputs: GenerateInsightsReqPayload): AnalyzeInternalDataAction<AnalyzeInternalDataState> => {
  return {
    type: ACTIONS.SET_FORM_DATA,
    data: {
      ...inputs,
    },
  };
};

export const setInstruction = (instruction: string): AnalyzeInternalDataAction<AnalyzeInternalDataState> => {
  return {
    type: ACTIONS.SET_INSTRUCTION,
    data: {
      instruction,
    },
  };
};
