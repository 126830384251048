import { DateRangeFilterBody } from '../../../commonComponents/types/dateRangeSelector';
import { MoveAnchorRequestBody } from '../../ModelScreen/types/anchor';
import { Note } from '../../ModelScreen/types/note';

export const apiPrefix = '/api/v2/recommended_anchors/project/';
export const SET_RECOMMENDED_NOTES_ANCHORS = 'SET_RECOMMENDED_NOTES_ANCHORS';
export const UPDATE_RECOMMENDED_NOTES_ANCHORS = 'UPDATE_RECOMMENDED_NOTES_ANCHORS';

export interface RecommendedAnchorBody {
  projectId: string;
  aspect_id: string;
  company_id: string;
}

export interface RecommendedAcceptHideBody {
  projectId: string;
  anchor_id: string;
  company_id?: string;
  note_id: string;
}

export interface RecommendedAcceptHideResponce {
  isSuccess: boolean;
  status: number;
  data: Note;
}

export interface RecommendedSignalsState {
  recommendedNotesAndAnchors: any[];
}

export interface RecommendedAnchorDetailsBody extends DateRangeFilterBody {
  projectId: string;
  anchor_id: string;
  company_id?: string;
}

export interface RecommendedAnchorFastSignalBody extends DateRangeFilterBody {
  projectId?: string;
  anchor_id: string;
  page_number?: number;
  page_size?: number;
  sort_by?: string;
  ascending?: boolean;
  note_id?: string;
  company_id?: string;
}

export interface RecommendedAnchorSourceBody {
  projectId?: string;
  anchor_id: string;
  company_id?: string;
}

export interface MoveRecommendationBody extends MoveAnchorRequestBody {
  company_id?: string;
}
