import { applyMiddleware, createStore } from 'redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';

import RootReducer, { ReduxState } from './reducers';

export default function configureStore(preloadedState?: ReduxState) {
  const devMiddelware = applyMiddleware(thunk, logger);
  const prodMiddleware = applyMiddleware(thunk);
  return createStore(
    RootReducer,
    preloadedState,
    process.env.NODE_ENV === 'development' ? devMiddelware : prodMiddleware
  );
}
