import {
  ACTIONS,
  Company,
  CurrentMarketSection,
  InternalSource,
  MarketGrowthSection,
  OverviewSection,
  PlaygroundAnalysisAction,
  ResearchPlan,
  ResearchPlanParts,
  SectionsState,
  Source,
  SuggestedQuestion,
  ValueChain,
} from '../../types';

export const clearAnalysis = (): PlaygroundAnalysisAction<SectionsState> => {
  return {
    type: ACTIONS.CLEAR_ANALYSIS,
  };
};

export const companiesInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['companies']> => {
  return {
    type: inProgress ? ACTIONS.COMPANIES_PENDING : ACTIONS.COMPANIES_COMPLETED,
  };
};

export const companiesSuccess = (data: Company[]): PlaygroundAnalysisAction<SectionsState['companies']> => {
  return {
    type: ACTIONS.COMPANIES_SUCCESS,
    payload: { data },
  };
};

export const companiesFailed = (): PlaygroundAnalysisAction<SectionsState['companies']> => {
  return {
    type: ACTIONS.COMPANIES_FAILED,
  };
};

export const insightsInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['insights']> => {
  return {
    type: inProgress ? ACTIONS.INSIGHTS_PENDING : ACTIONS.INSIGHTS_COMPLETED,
  };
};

export const insightsSuccess = (data: string[]): PlaygroundAnalysisAction<SectionsState['insights']> => {
  return {
    type: ACTIONS.INSIGHTS_SUCCESS,
    payload: { data },
  };
};

export const insightsFailed = (): PlaygroundAnalysisAction<SectionsState['insights']> => {
  return {
    type: ACTIONS.INSIGHTS_FAILED,
  };
};

export const suggestedQuestionsInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['suggested_questions']> => {
  return {
    type: inProgress ? ACTIONS.SUGGESTED_QUESTIONS_PENDING : ACTIONS.SUGGESTED_QUESTIONS_COMPLETED,
  };
};

export const suggestedQuestionsSuccess = (
  data: SuggestedQuestion[]
): PlaygroundAnalysisAction<SectionsState['suggested_questions']> => {
  return {
    type: ACTIONS.SUGGESTED_QUESTIONS_SUCCESS,
    payload: { data },
  };
};

export const suggestedQuestionsFailed = (): PlaygroundAnalysisAction<SectionsState['suggested_questions']> => {
  return {
    type: ACTIONS.SUGGESTED_QUESTIONS_FAILED,
  };
};

export const researchPlanInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['research_plan']> => {
  return {
    type: inProgress ? ACTIONS.RESEARCH_PLAN_PENDING : ACTIONS.RESEARCH_PLAN_COMPLETED,
  };
};

export const researchPlanSuccess = (data: ResearchPlan): PlaygroundAnalysisAction<SectionsState['research_plan']> => {
  return {
    type: ACTIONS.RESEARCH_PLAN_SUCCESS,
    payload: { data },
  };
};

export const researchPlanFailed = (): PlaygroundAnalysisAction<SectionsState['research_plan']> => {
  return {
    type: ACTIONS.RESEARCH_PLAN_FAILED,
  };
};

export const researchPlanPartsInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['research_plan_parts']> => {
  return {
    type: inProgress ? ACTIONS.RESEARCH_PLAN_PARTS_PENDING : ACTIONS.RESEARCH_PLAN_PARTS_COMPLETED,
  };
};

export const researchPlanPartsSuccess = (
  data?: ResearchPlanParts
): PlaygroundAnalysisAction<SectionsState['research_plan_parts']> => {
  return {
    type: ACTIONS.RESEARCH_PLAN_PARTS_SUCCESS,
    payload: { data },
  };
};

export const researchPlanPartsFailed = (): PlaygroundAnalysisAction<SectionsState['research_plan_parts']> => {
  return {
    type: ACTIONS.RESEARCH_PLAN_PARTS_FAILED,
  };
};

export const marketGrowthInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['market_growth']> => {
  return {
    type: inProgress ? ACTIONS.MARKET_GROWTH_PENDING : ACTIONS.MARKET_GROWTH_COMPLETED,
  };
};

export const marketGrowthSuccess = (
  data: MarketGrowthSection
): PlaygroundAnalysisAction<SectionsState['market_growth']> => {
  return {
    type: ACTIONS.MARKET_GROWTH_SUCCESS,
    payload: { data },
  };
};

export const marketGrowthFailed = (): PlaygroundAnalysisAction<SectionsState['market_growth']> => {
  return {
    type: ACTIONS.MARKET_GROWTH_FAILED,
  };
};

export const currentMarketInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['current_market']> => {
  return {
    type: inProgress ? ACTIONS.CURRENT_MARKET_PENDING : ACTIONS.CURRENT_MARKET_COMPLETED,
  };
};

export const currentMarketSuccess = (
  data: CurrentMarketSection
): PlaygroundAnalysisAction<SectionsState['current_market']> => {
  return {
    type: ACTIONS.CURRENT_MARKET_SUCCESS,
    payload: { data },
  };
};

export const currentMarketFailed = (): PlaygroundAnalysisAction<SectionsState['current_market']> => {
  return {
    type: ACTIONS.CURRENT_MARKET_FAILED,
  };
};

export const sourcesInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['sources']> => {
  return {
    type: inProgress ? ACTIONS.SOURCES_PENDING : ACTIONS.SOURCES_COMPLETED,
  };
};

export const sourcesSuccess = (data: Source[]): PlaygroundAnalysisAction<SectionsState['sources']> => {
  return {
    type: ACTIONS.SOURCES_SUCCESS,
    payload: { data },
  };
};

export const sourcesFailed = (): PlaygroundAnalysisAction<SectionsState['sources']> => {
  return {
    type: ACTIONS.SOURCES_FAILED,
  };
};

export const internalSourcesInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['internal_sources']> => {
  return {
    type: inProgress ? ACTIONS.INTERNAL_SOURCES_PENDING : ACTIONS.INTERNAL_SOURCES_COMPLETED,
  };
};

export const internalSourcesSuccess = (
  data: InternalSource[]
): PlaygroundAnalysisAction<SectionsState['internal_sources']> => {
  return {
    type: ACTIONS.INTERNAL_SOURCES_SUCCESS,
    payload: { data },
  };
};

export const internalSourcesFailed = (): PlaygroundAnalysisAction<SectionsState['internal_sources']> => {
  return {
    type: ACTIONS.INTERNAL_SOURCES_FAILED,
  };
};

export const overviewInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['overview']> => {
  return {
    type: inProgress ? ACTIONS.OVERVIEW_PENDING : ACTIONS.OVERVIEW_COMPLETED,
  };
};

export const overviewSuccess = (data: OverviewSection): PlaygroundAnalysisAction<SectionsState['overview']> => {
  return {
    type: ACTIONS.OVERVIEW_SUCCESS,
    payload: { data },
  };
};

export const overviewFailed = (): PlaygroundAnalysisAction<SectionsState['overview']> => {
  return {
    type: ACTIONS.OVERVIEW_FAILED,
  };
};

export const detailedAnalysisInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['detailed_analysis']> => {
  return {
    type: inProgress ? ACTIONS.DETAILED_ANALYSIS_PENDING : ACTIONS.DETAILED_ANALYSIS_COMPLETED,
  };
};

export const detailedAnalysisSuccess = (
  data: ValueChain
): PlaygroundAnalysisAction<SectionsState['detailed_analysis']> => {
  return {
    type: ACTIONS.DETAILED_ANALYSIS_SUCCESS,
    payload: { data },
  };
};

export const detailedAnalysisFailed = (): PlaygroundAnalysisAction<SectionsState['detailed_analysis']> => {
  return {
    type: ACTIONS.DETAILED_ANALYSIS_FAILED,
  };
};
