import { ACTIONS, InsightsState, ScenariosAnalysisAction } from '../../types';

const initialState: InsightsState = {
  loading: false,
  insights: [],
};

export const reducer = (state = initialState, action: ScenariosAnalysisAction<InsightsState>): InsightsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;
    case ACTIONS.CLEAR_QA_INSIGHTS:
      return initialState;
    case ACTIONS.QA_INSIGHTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.QA_INSIGHTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.QA_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: [...state.insights, ...(action.data?.insights ?? [])],
      };
    default:
      return state;
  }
};
