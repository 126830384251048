import { Dispatch } from 'redux';

import AxiosInstance from '../../Api/axios';
import { getCommonErrorResponse, getMongoAPIResponseInData, logApiError } from '../../Api/utils';
import {
  FETCH_PROJECTS,
  PROJECTS_COUNT,
  ProjectRequestBody,
  ProjectsListReqBody,
  SELECT_PROJECT,
  SearchProjectReqBody,
  UpdateProjectRequestBody,
  projectV2ApiPrefix,
} from '../types/project';

export const setProjects = (data: any) => {
  return {
    type: FETCH_PROJECTS,
    data,
  };
};

export const setProjectsCount = (data: any) => {
  return {
    type: PROJECTS_COUNT,
    data,
  };
};

export const setSelectedProject = (project: any) => {
  return {
    type: SELECT_PROJECT,
    data: project,
  };
};

export const fetchProjects = (body: ProjectRequestBody) => {
  const _window = window as any;
  const API_KEY = _window.env.client.REACT_APP_INVENTURIST_SECRET_KEY;
  return async (dispatch: Dispatch) => {
    const urlLink = `projects?userId=${body.userId}&secretKey=${API_KEY}`;
    return AxiosInstance.get(urlLink)
      .then((result: any) => {
        const res = { isSuccess: true, status: 200, message: '', data: result.data };
        dispatch(setProjects(res));
        return res;
      })
      .catch((error: any) => {
        const res = { data: [] as any[], ...getCommonErrorResponse(error) };
        dispatch(setProjects(res));
        logApiError(urlLink, error, body);
        return res;
      });
  };
};

/**
 * V2 portfolio API for fetching projects count
 */
export const fetchProjectsListCount = () => {
  return async (dispatch: Dispatch) => {
    const url = `${projectV2ApiPrefix}/count`;
    return AxiosInstance.get(url)
      .then((result: any) => {
        const resp = getMongoAPIResponseInData(result);
        dispatch(setProjectsCount(resp.data.count || 0));
        return resp;
      })
      .catch((error: any) => {
        logApiError(url, error);
        return getCommonErrorResponse(error);
      });
  };
};

/**
 * V2 portfolio API for fetching projects
 * @param body
 */
export const fetchProjectsList = (body: ProjectsListReqBody) => {
  return async (dispatch: Dispatch) => {
    const urlLink = `${projectV2ApiPrefix}?page=${body.page}&pageSize=${body.pageSize}`;
    return AxiosInstance.get(urlLink)
      .then((result: any) => {
        const response = getMongoAPIResponseInData(result);
        dispatch(setProjects(response));
        return response;
      })
      .catch((error: any) => {
        const res = { data: [] as any[], ...getCommonErrorResponse(error) };
        dispatch(setProjects(res));
        logApiError(urlLink, error, body);
        return res;
      });
  };
};

export const fetchProjectsDetailsByProjectId = async (projectId: string) => {
  const urlLink = `/projects/${projectId}`;

  try {
    const result = await AxiosInstance.get(urlLink);
    const res = { ...result, isSuccess: true, status: 200, message: '' };
    return res;
  } catch (error: any) {
    const res_1 = {
      isSuccess: false,
      message: '',
      data: [] as any[],
      status: error && error.response && error.response.status ? error.response.status : 503,
    };
    logApiError(urlLink, error, { projectId: projectId });
    return res_1;
  }
};

export const updateProjectsDetailsByProjectId = (body: UpdateProjectRequestBody) => {
  const { projectId, ...rest } = body;

  return async (dispatch: Dispatch) => {
    const urlLink = `/projects/${projectId}`;

    return AxiosInstance.put(urlLink, rest)
      .then((result: any) => {
        const res = { isSuccess: true, status: 200, message: '', ...result };
        return res;
      })
      .catch((error: any) => {
        const res = {
          isSuccess: false,
          message: '',
          data: [] as any[],
          status: error && error.response && error.response.status ? error.response.status : 503,
        };
        logApiError(urlLink, error, rest);
        return res;
      });
  };
};

/**
 * V2 API for portfolio to fetch project by Id
 * @param projectId
 */
export const fetchProjectById = async (projectId: string) => {
  const url = `${projectV2ApiPrefix}/${projectId}`;
  try {
    const result = await AxiosInstance.get(url);
    return getMongoAPIResponseInData(result);
  } catch (err) {
    logApiError(url, err, projectId);
    return getCommonErrorResponse(err);
  }
};

/**
 * V2 portfolio API for fetching projects by search
 */
export const searchProjectsByText = (body: SearchProjectReqBody) => {
  return async (dispatch: Dispatch) => {
    const url = `${projectV2ApiPrefix}/search`;
    return AxiosInstance.post(url, body)
      .then((result: any) => {
        return getMongoAPIResponseInData(result);
      })
      .catch((error: any) => {
        logApiError(url, error);
        return getCommonErrorResponse(error);
      });
  };
};
