import {
  Action,
  CLEAR_HEATMAP,
  ProductMarketFitState,
  REQUEST,
  SELECTED_PROJECTED_PMF_GENRALIZED_HEATMAP,
} from '../types/productMarketFit';

const initialState: ProductMarketFitState = {
  isFetching: false,
  pmfGeneralizedHeatmap: [],
};

const ProductMarketFit = (state = initialState, actions: Action): ProductMarketFitState => {
  switch (actions.type) {
    case REQUEST:
      return {
        ...state,
        isFetching: actions.data,
      };
    case SELECTED_PROJECTED_PMF_GENRALIZED_HEATMAP:
      return {
        ...state,
        isFetching: false,
        pmfGeneralizedHeatmap:
          actions.data !== undefined ? [...state.pmfGeneralizedHeatmap, actions.data] : state.pmfGeneralizedHeatmap,
      };
    case CLEAR_HEATMAP:
      return {
        ...state,
        isFetching: false,
        pmfGeneralizedHeatmap: [],
      };
    default:
      return state;
  }
};

export default ProductMarketFit;
