import axios from 'axios';

export const defaultAPITimeout = 180 * 1000; // 180 second server timeout

const AxiosInstance = axios.create({
  timeout: defaultAPITimeout,
  headers: {
    Accept: 'application/json text/plain, */*',
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Need for sending browser cookies in every request
});

export default AxiosInstance;
