import { PRIMARY_COLOR_LABEL, ThemeNames } from '../common/types/common';
import colors from '../constants/colors';

export * from './darkBlueTheme';
export * from './skyBlueTheme';

export const SCROLLBAR_STYLES = (selectedTheme: ThemeNames) => ({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '*::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '*::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '*::-webkit-scrollbar-thumb': {
            borderRadius: '3px',
            backgroundColor: colors[PRIMARY_COLOR_LABEL[selectedTheme]],
          },
          '*::-webkit-scrollbar-thumb:hover': {
            backgroundColor: colors.darkSkyBlue,
          },
        },
      },
    },
  },
});
