import { combineReducers } from 'redux';

import { ScenariosAnalysisAction, ScenariosAnalysisState } from '../types';

import {
  aspectsReducer,
  caseStudyInsightsReducer,
  inputsReducer,
  marketResearchInsightsReducer,
  marketSizeReducer,
  predictionsReducer,
  qaInsightsReducer,
  scenariosReducer,
  signalsReducer,
  swotInsightsReducer,
  userInputInsightsReducer,
} from './reducers';

export * from './actionCreators';

const ScenariosAnalysisReducer = combineReducers<ScenariosAnalysisState>({
  signals: signalsReducer,
  qaInsights: qaInsightsReducer,
  marketResearchInsights: marketResearchInsightsReducer,
  caseStudyInsights: caseStudyInsightsReducer,
  swotInsights: swotInsightsReducer,
  userInputInsights: userInputInsightsReducer,
  scenarios: scenariosReducer,
  inputs: inputsReducer,
  aspects: aspectsReducer,
  predictionsInsights: predictionsReducer,
  marketSizeInsights: marketSizeReducer,
});

const ScenariosAnalysis = (
  state?: ScenariosAnalysisState,
  action?: ScenariosAnalysisAction<ScenariosAnalysisState>
) => {
  return ScenariosAnalysisReducer(state, action as any);
};

export default ScenariosAnalysis;
