import { ACTIONS, Insight, InsightsState, ScenariosAnalysisAction } from '../../types';

export const clearSwotInsights = (): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.CLEAR_SWOT_INSIGHTS,
  };
};

export const swotInsightsInProgress = (inProgress = true): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: inProgress ? ACTIONS.SWOT_INSIGHTS_PENDING : ACTIONS.SWOT_INSIGHTS_COMPLETED,
  };
};

export const swotInsightsFetched = (insights: Insight[]): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.SWOT_INSIGHTS_SUCCESS,
    data: {
      insights,
    },
  };
};
