import {
  ACTIONS,
  InputsState,
  InsightsState,
  ScenarioAnchor,
  ScenarioCompany,
  ScenariosAnalysisAction,
} from '../../types';

export const clearAllInsights = (): ScenariosAnalysisAction<InsightsState> => {
  return {
    type: ACTIONS.CLEAR_ALL_INSIGHTS,
  };
};

export const clearInputs = (): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.CLEAR_INPUTS,
  };
};

export const inputsInProgress = (inProgress = true): ScenariosAnalysisAction<InputsState> => {
  return {
    type: inProgress ? ACTIONS.INPUTS_PENDING : ACTIONS.INPUTS_COMPLETED,
  };
};

export const anchorsFetched = (anchors: ScenarioAnchor[]): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.ANCHOR_SUCCESS,
    data: {
      anchors,
    },
  };
};

export const companiesFetched = (companies: ScenarioCompany[]): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.COMPANY_SUCCESS,
    data: {
      companies,
    },
  };
};

export const clearSelectedAnchors = (): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.CLEAR_SELECTED_ANCHORS,
  };
};

export const setSelectedAnchors = (selectedAnchors: string[]): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.SET_SELECTED_ANCHORS,
    data: { selectedAnchors },
  };
};

export const clearSelectedCompanies = (): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.CLEAR_SELECTED_COMPANIES,
  };
};

export const setSelectedCompanies = (selectedCompanies: string[]): ScenariosAnalysisAction<InputsState> => {
  return {
    type: ACTIONS.SET_SELECTED_COMPANIES,
    data: { selectedCompanies },
  };
};
