export const FETCH_SEARCH_ANCHOR_FILTERS = 'FETCH_SEARCH_ANCHOR_FILTERS';
export const FETCH_SEARCH_ANCHOR_LIST = 'FETCH_SEARCH_ANCHOR_LIST';
export const FETCH_SEARCH_ANCHOR_COUNT = 'FETCH_SEARCH_ANCHOR_COUNT';

export const SET_ANCHOR_FILTERS = 'SET_ANCHOR_FILTERS';
export const SET_ANCHOR_COUNT = 'SET_ANCHOR_COUNT';
export const SET_ANCHOR_RESULTS = 'SET_ANCHOR_RESULTS';

export interface SearchAnchorsCountRequestBody {
  aspect_ids?: string[];
  search_phrase: string;
  projectId?: string;
}

export interface SearchAnchorsCountResponse {
  isSuccess: boolean;
  status: number;
  message?: string;
  anchors_count: number;
}

export interface SearchAnchorsRequestBody {
  aspect_ids?: string[];
  search_phrase: string;
  page_number: number;
  page_size: number;
  projectId?: string;
}

export interface AnchorInitialState {
  filters: any[];
  count: number;
  results: any[];
}
