import { ACTIONS, Insight, MarketSizeState, ScenariosAnalysisAction, Signal } from '../../types';

export const clearMarketSizeInsights = (): ScenariosAnalysisAction<MarketSizeState> => {
  return {
    type: ACTIONS.CLEAR_MARKET_SIZE_INSIGHTS,
  };
};

export const marketSizeInsightsInProgress = (inProgress = true): ScenariosAnalysisAction<MarketSizeState> => {
  return {
    type: inProgress ? ACTIONS.MARKET_SIZE_INSIGHTS_PENDING : ACTIONS.MARKET_SIZE_INSIGHTS_COMPLETED,
  };
};

export const marketSizeInsightsFetched = (insights: Insight[]): ScenariosAnalysisAction<MarketSizeState> => {
  return {
    type: ACTIONS.MARKET_SIZE_INSIGHTS_SUCCESS,
    data: {
      insights,
    },
  };
};

export const marketSizeSignalsFetched = (
  signals: Signal[],
  paginationToken: string
): ScenariosAnalysisAction<MarketSizeState> => {
  return {
    type: ACTIONS.MARKET_SIZE_SIGNALS_SUCCESS,
    data: {
      signals,
      paginationToken,
    },
  };
};

export const marketSizeSignalsCountFetched = (count: number): ScenariosAnalysisAction<MarketSizeState> => {
  return {
    type: ACTIONS.MARKET_SIZE_SIGNALS_COUNT_SUCCESS,
    data: { signalsCount: count },
  };
};
