import { Note } from '../../ModelScreen/types/note';

import { FilterOption } from './filter';

export const SET_NOTES_FILTER = 'SET_NOTES_FILTER';
export const SET_NOTES_COUNT = 'SET_NOTES_COUNT';
export const SET_NOTES_RESULTS = 'SET_NOTES_RESULTS';
export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_NOTES_SELECTED_FILTER = 'SET_NOTES_SELECTED_FILTER';

export interface NoteList {
  pageNumber: number;
  rowsPerPage: number;
  notes: Note[] | [];
  filters: FilterOption[] | [];
  selectedFilters: string[];
  totalNoteCount: number;
}

export interface FetchFiltersResponse {
  isSuccess: boolean;
  status: number;
  filter?: Filter[];
  message?: string;
}

export interface Filter {
  aspect_external_name: string;
  aspect_id: string;
  aspect_internal_name: string;
}

export interface FetchNotesCountBody {
  search_phrase: string;
  projectId: string;
  aspect_ids: string[];
}

export interface FetchNotesCountResponse {
  isSuccess: boolean;
  status: number;
  message?: string;
  notes_count: number;
}

export interface FetchNotesBody {
  search_phrase: string;
  aspect_ids?: string[] | [];
  page_number: number;
  page_size: number;
  projectId: string;
}

export interface FetchNotesResponse {
  isSuccess: boolean;
  status: number;
  allNotes?: Note[];
  message?: string;
}

export interface FetchNoteParams {
  type: '' | 'search_phrase' | 'aspect_ids' | 'page_number' | 'page_size';
  value: any;
}

export interface FetchNoteCountParams {
  type: '' | 'search_phrase' | 'aspect_ids';
  value: any;
}
