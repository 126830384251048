import { ACTIONS, AIGeneratedAnswersAction, AiGeneratedAnalysisSignals } from '../../types';

export * from './inputs';
export * from './signals';

export const clearAiGeneratedAnswers = (): AIGeneratedAnswersAction<AiGeneratedAnalysisSignals> => {
  return {
    type: ACTIONS.CLEAR_AI_GENERATED_ANSWERS,
  };
};
