import makeStyles from '@mui/styles/makeStyles';

import colors from '../../constants/colors';
import { PRIMARY_COLOR_LABEL, ThemeNames } from '../types/common';

interface BaseStylesProps {
  theme: ThemeNames;
  drawerWidth?: number;
}

export const useBaseStyles = makeStyles<unknown, BaseStylesProps>(() => ({
  vh_center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  v_center: {
    display: 'flex',
    alignItems: 'center',
  },
  h_space_between: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  h_center: {
    display: 'flex',
    justifyContent: 'center',
  },
  h_flex_end: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
  montserrat_regular_14: {
    font: 'normal 14px Montserrat !important',
    color: colors.grey3,
    letterSpacing: 0,
  },
  montserrat_bold_14: {
    font: 'bold 14px Montserrat !important',
    color: colors.white,
    letterSpacing: 0,
  },
  montserrat_bold_22: {
    font: 'bold 22px Montserrat !important',
    letterSpacing: 0,
  },
  montserrat_bold_18: {
    font: 'bold 18px Montserrat !important',
    letterSpacing: 0,
  },
  ptsans_bold_20: {
    font: 'bold 20px PT Sans !important',
    color: colors.black2,
    letterSpacing: 0,
  },
  ptsans_bold_25: {
    font: 'bold 25px PT Sans !important',
    color: colors.black2,
    letterSpacing: 0,
  },
  montserrat_bold_16_grey: {
    font: 'bold 16px Montserrat !important',
    color: colors.grey1,
    letterSpacing: 0,
  },
  montserrat_bold_16: {
    font: 'bold 16px Montserrat !important',
    letterSpacing: 0,
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  container: ({ drawerWidth = 0 }) => ({
    width: `calc(100% - ${drawerWidth}px)`,
    height: '100% !important',
    marginLeft: `${drawerWidth}px`,
    paddingLeft: 13,
    paddingRight: 13,
    paddingTop: 13,
  }),
  overlayLoadingContainer: () => ({
    position: 'absolute',
    zIndex: 11,
    top: '50%',
    left: '50%',
  }),
  cursorPointer: {
    cursor: 'pointer',
  },
  hideScrollbar: {
    '-ms-overflow-style': 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
    },
  },
  ptsans_regular_20: {
    fontFamily: 'PT Sans',
    fontSize: 20,
    letterSpacing: 0,
  },
  ptsans_regular_16: {
    fontFamily: 'PT Sans',
    fontSize: 16,
    letterSpacing: 0,
  },
  ptsans_regular_14: {
    fontFamily: 'PT Sans',
    fontSize: 14,
    letterSpacing: 0,
  },
  ptsans_bold_12: {
    font: 'bold 12px PT Sans !important',
    letterSpacing: 0,
  },
  ptsans_bold_14: {
    fontFamily: 'PT Sans',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  ptsans_bold_16: {
    fontFamily: 'PT Sans',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  sansPro_bold_16: {
    fontFamily: 'Source Sans Pro',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
  },
  montserrat_regular_12: {
    font: 'normal 12px Montserrat !important',
    letterSpacing: 0,
  },
  montserrat_bold_12: {
    font: 'bold 12px Montserrat !important',
    letterSpacing: 0,
  },
  montserrat_regular_11: {
    font: 'normal 11px Montserrat !important',
    letterSpacing: 0,
  },
  montserrat_regular_16: {
    font: 'normal 16px Montserrat !important',
    letterSpacing: 0,
  },
  segoeUI_regular_12: {
    fontFamily: 'Segoe UI',
    fontSize: 12,
    letterSpacing: 0,
  },
  inlineFlex: {
    display: 'inline-flex',
  },
  gibson__regular_18: {
    fontFamily: 'Gibson',
    fontSize: 18,
    letterSpacing: '0.36px',
  },
  outlineSqureButton: {
    textTransform: 'capitalize',
    borderRadius: 0,
    fontWeight: 'bold',
    padding: '6px 21px',
    width: 'fit-content',
  },
  primaryButton: {
    textTransform: 'initial',
    font: 'bold 14px Montserrat !important',
    letterSpacing: 1,
    padding: '9px 16px',
  },
  defaultFontFamily: {
    fontFamily: 'Montserrat !important',
  },
  pagination: {
    height: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullHeight: {
    height: '100%',
  },
  staticMockupHeading: ({ theme }) => ({
    background: colors[PRIMARY_COLOR_LABEL[theme]],
    color: colors.white,
    padding: '5px 7px 5px 6px',
    width: 'fit-content',
  }),
  montserrat_bold: {
    fontWeight: 500,
    fontFamily: 'Montserrat !important',
    letterSpacing: 0,
  },
  flex: {
    display: 'flex',
  },
  requiredIcon: ({ theme }) => ({
    color: colors[PRIMARY_COLOR_LABEL[theme]],
    fontSize: 9,
    marginTop: 4,
  }),
  sansPro_regular_14: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  accordionTextSelectable: {
    userSelect: 'text',
    cursor: 'text',
  },
  accordionTextSelectableAndClickable: {
    userSelect: 'text',
  },
}));
