import { Dispatch } from 'redux';

import AxiosInstance from '../../Api/axios';
import { getCommonErrorResponse, logApiError } from '../../Api/utils';
import { SET_USER_DETAILS, SET_USER_ID } from '../types/signin';
import { FetchUserReponse, SET_USER_PLANS } from '../types/user';

import { signinSuccessFailure, updateLastSeen } from './signin';

export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const setUserId = (data: string) => {
  return {
    type: SET_USER_ID,
    data,
  };
};

export const setUserDetails = (data: object) => {
  return {
    type: SET_USER_DETAILS,
    data,
  };
};

export const clearAllState = () => {
  return {
    type: SIGNOUT_REQUEST,
  };
};

export const setUserPlans = (data: object) => {
  return {
    type: SET_USER_PLANS,
    data,
  };
};

export const retriveLoggedInUserId = () => {
  return async (dispatch: Dispatch) => {
    const url = '/api/auth/currentUser';
    try {
      const result = await AxiosInstance.get(url);
      dispatch(setUserId(result.data));
      return { isSuccess: !!result.data, status: 200, data: result.data };
    } catch (err) {
      logApiError(url, err);
      return { isSuccess: false, status: 401 };
    }
  };
};

export const retriveLoggedInUser = (userId: string, notStoreInRedux?: boolean) => {
  const url = `/api/users/${userId}`;
  return (dispatch: Dispatch) => {
    return AxiosInstance.get(url)
      .then((result: any) => {
        const response: FetchUserReponse = { isSuccess: true, status: result.status, ...result.data };
        if (!notStoreInRedux) {
          dispatch(setUserDetails(result.data.user));
        }
        return response;
      })
      .catch((err: any) => {
        const response: FetchUserReponse = getCommonErrorResponse(err);
        response.user = null;
        logApiError(url, err, userId);
        return response;
      });
  };
};

export const signout = () => {
  return async (dispatch: Dispatch) => {
    const url = 'api/auth/signout';
    try {
      const result = await AxiosInstance.post(url, {}, { withCredentials: true });
      dispatch(clearAllState());
      return { isSuccess: true, status: 200, ...result.data };
    } catch (err) {
      logApiError(url, err);
      return getCommonErrorResponse(err);
    }
  };
};

export const getUserPlanById = (userID: string) => {
  const url = `api/user/${userID}/userPlan`;
  return (dispatch: Dispatch) => {
    return AxiosInstance.get(url)
      .then((result: any) => {
        const response = { isSuccess: true, status: 200, data: result.data };
        dispatch(setUserPlans(response));
        return response;
      })
      .catch((err: any) => {
        dispatch(setUserPlans({ isSuccess: false, status: 400 }));
        logApiError(url, err, userID);
        return getCommonErrorResponse(err);
      });
  };
};

export const signupFromInvitation = (body: any) => {
  const url = `/api/users/update/${body.user._id}`;
  return (dispatch: Dispatch) => {
    return AxiosInstance.put(url, body)
      .then((result: any) => {
        const response = { isSuccess: true, status: 200, data: result.data };
        updateLastSeen(result.data._id);
        dispatch(setUserId(result.data._id));
        dispatch(signinSuccessFailure({ isSuccess: true, response: result.data, message: '' }));
        dispatch(setUserDetails(result.data));
        return response;
      })
      .catch((err: any) => {
        dispatch(signinSuccessFailure({ isSuccess: false, response: {}, message: '' }));
        dispatch(setUserDetails({}));
        dispatch(setUserId(''));
        logApiError(url, err, body);
        return getCommonErrorResponse(err);
      });
  };
};
