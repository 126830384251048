import { Signal } from './signals';

export interface InsightsCommonReqBody {
  signals: string[];
  scenario_id: string;
}

export interface InsightsUserInputReqBody extends InsightsCommonReqBody {
  question: string;
}

export interface InsightsPredictionReqBody extends InsightsCommonReqBody {
  anchor_ids?: string[];
  company_ids?: string[];
}

export interface InsightsMarketSizeReqBody extends InsightsCommonReqBody {
  anchor_ids?: string[];
  company_ids?: string[];
}

export interface InsightsMarketResearchReqBody extends InsightsCommonReqBody {}
export interface InsightsCaseStudyReqBody extends InsightsCommonReqBody {}
export interface InsightsQAReqBody extends InsightsCommonReqBody {}

export interface InsightsSwotAnalysisReqBody extends InsightsCommonReqBody {
  company?: string;
}

export interface Insight {
  text: string;
  signals: Signal[];
}

export enum INSIGHTS_TYPES {
  'Q&A' = 'Q&A',
  Market_research = 'Market research',
  SWOT_analysis = 'SWOT analysis',
  Case_study = 'Case study',
  user_input = 'User input',
  predictions = 'Predictions',
  market_size = 'Market size',
}
