import { ACTIONS, AIGeneratedAnswersAction, AiGeneratedAnalysisInputs } from '../../types';

export const setTopic = (value: string): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.SET_TOPIC_INPUT,
    data: { topicInput: value },
  };
};

export const setTopicLoading = (inProgress = true): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: inProgress ? ACTIONS.SET_TOPIC_PENDING : ACTIONS.SET_TOPIC_COMPLETED,
  };
};

export const setTopicError = (hasError = true): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: hasError ? ACTIONS.SET_TOPIC_ERROR : ACTIONS.CLEAR_TOPIC_ERROR,
  };
};

export const setAnchorsInputValue = (value: string[]): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.SET_ANCHORS_INPUT,
    data: { anchorsInput: value },
  };
};

export const clearAnchorsInputValue = (): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.CLEAR_ANCHORS_INPUT,
  };
};

export const setCompaniesInputValue = (value: string[]): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.SET_COMPANIES_INPUT,
    data: { companiesInput: value },
  };
};

export const clearCompaniesInputValue = (): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.CLEAR_COMPANIES_INPUT,
  };
};

export const setQuestionInputValue = (value: string): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.SET_QUESTION_INPUT,
    data: { questionInput: value },
  };
};

export const clearQuestionInputValue = (): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.CLEAR_QUESTION_INPUT,
  };
};

export const setCustomQuestion = (value: string): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.SET_CUSTOM_QUESTION_INPUT,
    data: { customQuestionInput: value },
  };
};

export const clearCustomQuestion = (): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.CLEAR_CUSTOM_QUESTION_INPUT,
  };
};

export const setFormOpenAiError = (): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.SET_FORM_OPEN_AI_ERROR,
  };
};

export const clearFormOpenAiError = (): AIGeneratedAnswersAction<AiGeneratedAnalysisInputs> => {
  return {
    type: ACTIONS.CLEAR_FORM_OPEN_AI_ERROR,
  };
};
