import { ACTIONS, AIGeneratedAnswersAction, AiGeneratedAnalysisInputs } from '../../types';

const initialState: AiGeneratedAnalysisInputs = {
  topicInput: '',
  anchorsInput: [],
  companiesInput: [],
  questionInput: '',
  customQuestionInput: '',
  topicLoading: false,
  topicError: false,
  openAiError: false,
};

export const inputsReducer = (
  state = initialState,
  action: AIGeneratedAnswersAction<AiGeneratedAnalysisInputs>
): AiGeneratedAnalysisInputs => {
  switch (action.type) {
    case ACTIONS.CLEAR_COMPANIES_INPUT:
      return { ...state, companiesInput: initialState.companiesInput };
    case ACTIONS.SET_COMPANIES_INPUT:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_CUSTOM_QUESTION_INPUT:
      return { ...state, customQuestionInput: initialState.customQuestionInput };
    case ACTIONS.SET_CUSTOM_QUESTION_INPUT:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_ANCHORS_INPUT:
      return { ...state, anchorsInput: initialState.anchorsInput };
    case ACTIONS.SET_ANCHORS_INPUT:
      return { ...state, ...action.data };
    case ACTIONS.CLEAR_QUESTION_INPUT:
      return {
        ...state,
        questionInput: initialState.questionInput,
      };
    case ACTIONS.SET_QUESTION_INPUT:
      return { ...state, ...action.data };
    case ACTIONS.SET_TOPIC_INPUT:
      return { ...state, ...action.data };
    case ACTIONS.SET_TOPIC_PENDING:
      return { ...state, topicLoading: true };
    case ACTIONS.SET_TOPIC_COMPLETED:
      return { ...state, topicLoading: false };
    case ACTIONS.SET_TOPIC_ERROR:
      return { ...state, topicError: true };
    case ACTIONS.CLEAR_TOPIC_ERROR:
      return { ...state, topicError: false };
    case ACTIONS.SET_FORM_OPEN_AI_ERROR:
      return { ...state, openAiError: true };
    case ACTIONS.CLEAR_FORM_OPEN_AI_ERROR:
      return { ...state, openAiError: false };
    case ACTIONS.CLEAR_AI_GENERATED_ANSWERS:
      return initialState;
    default:
      return state;
  }
};
