export type StringWithSeparator<T extends string, Delimiter extends string> = `${T}${Delimiter}${T}`;

export interface SidebarMenuItems {
  label: string;
  link: string;
  type: string;
  allowed: boolean;
}

export interface SidebarMenuItemsWithFixedIcon extends SidebarMenuItems {
  icon: JSX.Element;
}

export interface SidebarMenuItemsWithActiveIcon extends SidebarMenuItems {
  active: string;
  inActive: string;
}

export type ThemeNames = 'DARK_BLUE' | 'SKY_BLUE';

export const PRIMARY_COLOR_LABEL: Record<ThemeNames, string> = {
  DARK_BLUE: 'primaryBlue',
  SKY_BLUE: 'secondaryBlue',
} as const;
export const COLOR_SELECTOR_LABEL: Record<ThemeNames, string> = {
  DARK_BLUE: 'Dark Blue',
  SKY_BLUE: 'Sky Blue',
} as const;
