import { Anchor } from './anchor';

export const SET_SELECTED_ASPECT_NOTE = 'SET_SELECTED_ASPECT_NOTE';

export interface Note {
  id: string;
  is_label_generated: boolean;
  label: string;
  score?: number;
  anchors_recommendations: Anchor[];
  // Extra fields added by frontend
  showInput?: boolean;
  anchorInputValue?: string;
  type: string;
  showTitleInput?: boolean;
  inputTitleValue?: string;
  draggableId?: string;
  noteExpanded?: boolean;
  aspect?: { id: string; label: string };
  tags?: string[];
}
export interface CreateNoteRequestBody {
  anchors_ids: string[];
  aspect_id: string;
  project_id: string;
}
export interface RenameNoteRequestBody {
  note_id: string;
  label: string;
}
export interface SourceBody {
  anchor_id: string;
  container_id: string;
  container_type: 'aspect' | 'note';
}
/**
 * 1. new note from source and destination anchors (note label will be generated):
 * in this case source destination project_id and new_note_aspect_id must be provided
 *
 * 2.new note with no anchors and custom note label: in this case project_id new_note_aspect_id
 *  and new_note_label must be provided
 */
export interface CreateNoteParams {
  source?: SourceBody[];
  destination?: SourceBody[];
  project_id: string;
  new_note_aspect_id: string;
  new_note_label?: string;
}
export interface CreateNoteResponse {
  isSuccess: boolean;
  status: number;
  data: { aspects: UpdatedAspectDetails[] } | null;
}
export interface UpdatedAspectDetails {
  notes: Note[];
  orphan_anchors_recommendations: Anchor[];
  id: string;
  score: number;
}
export interface MoveNoteRequestBody {
  note_id: string;
  project_id: string;
  from_aspect_id: string;
  to_aspect_id: string;
}
export interface RemoveNoteRequestBody {
  note_id: string;
}
