import { Action } from '../../ModelScreen/types/model';
import {
  RecommendedSignalsState,
  SET_RECOMMENDED_NOTES_ANCHORS,
  UPDATE_RECOMMENDED_NOTES_ANCHORS,
} from '../types/recommendedSignal';

const initialState: RecommendedSignalsState = {
  recommendedNotesAndAnchors: [],
};

const CompanyRecommendation = (state = initialState, actions: Action): RecommendedSignalsState => {
  switch (actions.type) {
    case SET_RECOMMENDED_NOTES_ANCHORS:
      return {
        ...state,
        recommendedNotesAndAnchors: [...state.recommendedNotesAndAnchors, actions.data],
      };
    case UPDATE_RECOMMENDED_NOTES_ANCHORS:
      return {
        ...state,
        recommendedNotesAndAnchors: actions.data,
      };
    default:
      return state;
  }
};

export default CompanyRecommendation;
