import { ACTIONS, PredictionsState, ScenariosAnalysisAction } from '../../types';

const initialState: PredictionsState = {
  loading: false,
  insights: [],
  signals: [],
  signalsCount: 0,
};

export const reducer = (state = initialState, action: ScenariosAnalysisAction<PredictionsState>): PredictionsState => {
  switch (action.type) {
    case ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;
    case ACTIONS.CLEAR_PREDICTIONS_INSIGHTS:
      return initialState;
    case ACTIONS.PREDICTIONS_INSIGHTS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.PREDICTIONS_INSIGHTS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.PREDICTIONS_INSIGHTS_SUCCESS:
      return {
        ...state,
        insights: [...state.insights, ...(action.data?.insights ?? [])],
      };
    case ACTIONS.PREDICTIONS_SIGNALS_COUNT_SUCCESS:
      return {
        ...state,
        signalsCount: action.data?.signalsCount ?? 0,
      };
    case ACTIONS.PREDICTIONS_SIGNALS_SUCCESS:
      return {
        ...state,
        signals: [...state.signals, ...(action.data?.signals ?? [])],
        paginationToken: action.data?.paginationToken,
      };
    default:
      return state;
  }
};
