import { ACTIONS, Aspect, AspectsState, ScenariosAnalysisAction } from '../../types';

export const clearAspects = (): ScenariosAnalysisAction<AspectsState> => {
  return {
    type: ACTIONS.CLEAR_ASPECTS,
  };
};

export const aspectsInProgress = (inProgress = true): ScenariosAnalysisAction<AspectsState> => {
  return {
    type: inProgress ? ACTIONS.ASPECTS_PENDING : ACTIONS.ASPECTS_COMPLETED,
  };
};

export const aspectsFetched = (aspects: Aspect[]): ScenariosAnalysisAction<AspectsState> => {
  return {
    type: ACTIONS.ASPECTS_SUCCESS,
    data: {
      aspects,
    },
  };
};

export const clearSelectedAspects = (): ScenariosAnalysisAction<AspectsState> => {
  return {
    type: ACTIONS.CLEAR_SELECTED_ASPECTS,
  };
};

export const setSelectedAspects = (aspectId: string): ScenariosAnalysisAction<AspectsState & { aspectId: string }> => {
  return {
    type: ACTIONS.SET_SELECTED_ASPECTS,
    data: { aspectId },
  };
};
