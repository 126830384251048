import { Action, NEW_MESSAGE_RECEIVED, PUBSUB_CONNECTED, PubSubStates, UPDATE_MESSAGES } from '../types/webpubsub';

const initial: PubSubStates = {
  connection: {
    connectionId: '',
    event: '',
    type: '',
    userId: '',
    timeStamp: 0,
  },
  newMessage: {
    event: '',
    project: '',
    timeStamp: 0,
  },
  messages: [],
};
const WebPubSub = (state = initial, actions: Action) => {
  switch (actions.type) {
    case PUBSUB_CONNECTED:
      return {
        ...state,
        connection: actions.data,
      };
    case NEW_MESSAGE_RECEIVED:
      return {
        ...state,
        newMessage: actions.data,
      };
    case UPDATE_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, actions.data],
      };
    default:
      return state;
  }
};
export default WebPubSub;
