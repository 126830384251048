import { Dispatch } from 'redux';

import AxiosInstance from '../../Api/axios';
import { logApiError } from '../../Api/utils';
import { UserDetails } from '../reducers/authentication';
import { SigninActionType, SigninSuccessFailureObj } from '../types/signin';

import { setUserId } from './user';

export const REQUEST_USER_SIGNIN = 'REQUEST_USER_SIGNIN';
export const SIGNIN_USER_SUCCESS_FAILURE = 'SIGNIN_USER_SUCCESS_FAILURE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

export function requestUserSignin(): SigninActionType {
  return {
    type: REQUEST_USER_SIGNIN,
  };
}

export function signinSuccessFailure(signinSuccessFailureObj: SigninSuccessFailureObj) {
  return {
    type: SIGNIN_USER_SUCCESS_FAILURE,
    data: signinSuccessFailureObj,
  };
}

export function setUserDetails(data: UserDetails) {
  return {
    type: SET_USER_DETAILS,
    data,
  };
}

export interface Body {
  username: string;
  password: string;
  projectId: string;
  secretKey: string;
}

export function Signin(body: Body) {
  return async (dispatch: Dispatch) => {
    dispatch(requestUserSignin());
    const url = 'api/auth/signin';
    try {
      const res = await AxiosInstance.post(url, body);
      const result = { isSuccess: true, status: 200, response: res.data, message: '' };
      updateLastSeen(res.data._id);
      dispatch(setUserId(res.data._id));
      dispatch(setUserDetails(res.data));
      dispatch(signinSuccessFailure(result));
      return result;
    } catch (error: any) {
      const result = {
        isSuccess: false,
        response: [],
        message: error && error.message && error.message.message ? error.message.message : '',
        status: 401,
      };
      dispatch(signinSuccessFailure(result));
      dispatch(setUserDetails({}));
      logApiError(url, error, body);
      return result;
    }
  };
}

/**
 * To update user last seen
 * @param userId id of the user
 */
export const updateLastSeen = (userId: string) => {
  if (userId) {
    AxiosInstance.patch(`/api/users/${userId}/updateLastSeen`);
  }
};
